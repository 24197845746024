import _ from "lodash";

// basic entities translations
import customerTranslations from "./customerTranslations";
import insuranceTranslations from "./insuranceTranslations";

// single product translations
import alimonyTranslations from "./alimonyTranslations";
import deathTranslations from "./deathTranslations";
import divorceTranslations from "./divorceTranslations";
import eventContractTranslations from "./eventContractTranslations";
import familyLawCaseTranslations from "./familyLawCaseTranslations";
import membershipContractTranslations from "./membershipContractTranslations";
import otherContractTranslations from "./otherContractTranslations";
import patientDecreeTranslations from "./patientDecreeTranslations";
import powerOfAttorneyTranslations from "./powerOfAttorneyTranslations";
import processCostSupportTranslations from "./processCostSupportTranslations";
import providerContractTranslations from "./providerContractTranslations";
import purchaseContractTranslations from "./purchaseContractTranslations";
import serviceContractTranslations from "./serviceContractTranslations";
import settlementTranslations from "./settlementTranslations";
import shortTimeWorkTranslations from "./shortTimeWorkTranslations";
import subscriptionContractTranslations from "./subscriptionContractTranslations";
import trafficTranslations from "./trafficTranslations";
import travelContractTranslations from "./travelContractTranslations";
import vehicleContractTranslations from "./vehicleContractTranslations";
import authorityTranslations from "./authorityTranslations";
import allDataFormTranslations from "./allDataFormTranslations";
import assessmentTranslations from "./assessmentTranslations";
import faxTranslations from "./faxTranslations";
import historyItemTranslations from "./historyItemTranslations";
import generalTranslations from "./generalTranslations";
import paidTaskTranslations from "./paidTaskTranslations";
import contractClaimTranslations from "./contractClaimTranslations";
import participantTranslations from "./participantTranslations";
import courtAppointmentTranslations from "./courtAppointmentTranslations";
import beaMessageTranslations from "./beaMessageTranslations";
import applicationTranslations from "./applicationTranslations";
import backofficeUserTranslations from "./backofficeUserTranslations";
import externalUserTranslations from "./externalUserTranslations";
import contractualPartnerLegalEntityTranslations from "./contractualPartnerLegalEntityTranslations";
import debtCollectionAgencyTranslations from "./debtCollectionAgencyTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import personTranslations from "./personTranslations";
import { courtTranslations } from "./courtTranslations";
import messagingTranslations from "./messagingTranslations";
import rentalContractTranslations from "./rentalContractTranslations";
import employmentContractTranslations from "./employmentContractTranslations";
import dataLeakContractTranslations from "./dataLeakContractTranslations";
import wireTransferTranslations from "./wireTransferTranslations";
import { AbstractCase } from "../../types/AbstractCase";

const translations = {
  alimony: alimonyTranslations,
  allDataForm: allDataFormTranslations,
  assessment: assessmentTranslations,
  authority: authorityTranslations,
  prosecution: prosecutionTranslations,
  court: courtTranslations,
  beaMessage: beaMessageTranslations,
  customer: customerTranslations,
  person: personTranslations,
  death: deathTranslations,
  dataLeakContract: dataLeakContractTranslations,
  divorce: divorceTranslations,
  employmentContract: employmentContractTranslations,
  eventContract: eventContractTranslations,
  familyLawCase: familyLawCaseTranslations,
  fax: faxTranslations,
  historyItem: historyItemTranslations,
  insurance: insuranceTranslations,
  membershipContract: membershipContractTranslations,
  otherContract: otherContractTranslations,
  paidTask: paidTaskTranslations,
  patientDecree: patientDecreeTranslations,
  powerOfAttorney: powerOfAttorneyTranslations,
  processCostSupport: processCostSupportTranslations,
  providerContract: providerContractTranslations,
  purchaseContract: purchaseContractTranslations,
  rentalContract: rentalContractTranslations,
  serviceContract: serviceContractTranslations,
  settlement: settlementTranslations,
  shortTimeWork: shortTimeWorkTranslations,
  subscriptionContract: subscriptionContractTranslations,
  traffic: trafficTranslations,
  travelContract: travelContractTranslations,
  vehicleContract: vehicleContractTranslations,
  participant: participantTranslations,
  courtAppointment: courtAppointmentTranslations,
  general: generalTranslations,
  application: applicationTranslations,
  backofficeUser: backofficeUserTranslations,
  externalUser: externalUserTranslations,
  contractualPartnerLegalEntity: contractualPartnerLegalEntityTranslations,
  debtCollectionAgency: debtCollectionAgencyTranslations,
  messaging: messagingTranslations,
  wireTransfer: wireTransferTranslations,
};

export const translate = (path: string, defaultValue?: string) => {
  return _.get(translations, path, defaultValue || "TODO:" + path);
};

export const translateSelectValues = (
  path: string,
  value: string,
  productClassName: AbstractCase["productClassName"]
) => {
  const translationObject = _.get(translations[productClassName], path);
  if (translationObject.hasOwnProperty("values")) {
    return translate(productClassName + "." + path + ".values." + value);
  }
  return value;
};

export const getFlatTranslationPathsWithLabel = (rootObject: keyof typeof translations | "contractLaw") => {
  if (rootObject === "contractLaw") {
    return flatten(contractClaimTranslations);
  }
  if (!translations[rootObject]) {
    return [];
  }
  return flatten(translations[rootObject]);
};

export function flatten(
  object: Record<string, any>,
  path: string | null = null,
  separator: string = "."
): Record<string, any> {
  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const newPath = Array.isArray(object) ? `${path ? path : ""}[${key}]` : [path, key].filter(Boolean).join(separator);
    const isObject = [
      typeof value === "object",
      value !== null,
      !(value instanceof Date),
      !(value instanceof RegExp),
      !(Array.isArray(value) && value.length === 0),
    ].every(Boolean);

    return isObject ? { ...acc, ...flatten(value, newPath, separator) } : { ...acc, [newPath]: value };
  }, {});
}
