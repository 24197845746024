import React, { useEffect, useMemo } from "react";
import ButtonLoading from "../../Button/ButtonLoading";
import { Box, Grid } from "@mui/material";
import { useCommunicationMedium } from "./CommunicationMediumProvider";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import SendForm from "./SendForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { formValue } from "../../../services/formServiceFunctions";
import { matchRegexp, requiredFieldDefault } from "../../../services/validationRules";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import useForm from "../../../hooks/useForm";
import { getFaxNumber } from "./communicationHelperFunctions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createResource, fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function FaxSend() {
  const {
    product,
    open,
    handleClose,
    assignedLawyerHasBea,
    recipientType,
  } = useCommunicationMedium();
  const currentUser = useCurrentUser();
  const createMutation = useMutation(createResource);

  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };
  const { data: mediaObjectsCollection } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const mediaObjects = _.get(mediaObjectsCollection, "hydra:member");

  const faxNumber = useMemo(() => {
    return getFaxNumber(product, recipientType);
  }, [product, recipientType]);

  const initialValues = {
    attachments: [],
    legalDocuments: [],
    fax: "",
    faxAttachment: "",
  };

  const onSubmit = async ({ values }: { values: any }) => {
    try {
      const backofficeCase = _.get(product, "backofficeCase") || { "@id": "unknown" };
      await createMutation.mutateAsync({
        uri: "faxes",
        data: {
          mediaObject: values.faxAttachment,
          targetNumber: values.fax,
          backofficeCase: backofficeCase["@id"],
          createdBy: currentUser["@id"],
          forceSend: true,
        },
      });
    } catch (e) {
      alert(JSON.stringify(e));
    }
    handleClose();
  };

  const { values, handleChange, handleBlur, handleSubmit, isLoading, registerValidators, errors } = useForm({
    initialValues,
    onSubmit,
    identifier: "" + product.id,
    clearFormAfterSubmit: true,
  });

  useEffect(() => {
    handleChange({
      target: {
        name: "fax",
        value: faxNumber,
      },
    });
  }, [faxNumber]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      disableBackdropClick
      title={"Fax versenden"}
      submitButton={
        <ButtonLoading type={"submit"} variant={"contained"} onClick={handleSubmit} isLoading={isLoading}>
          Nachricht Versenden
        </ButtonLoading>
      }
    >
      {!assignedLawyerHasBea && (
        <Box sx={{ color: "red", textAlign: "center" }}>
          Achtung: beA-Versand nicht möglich. Es sind keine beA-Daten für den zugeordneten Anwalt hinterlegt.
        </Box>
      )}
      <SendForm>
        <Grid item xs={12}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"fax"}
            value={formValue(values, "fax")}
            label={"Faxnummer"}
            registerValidators={registerValidators}
            validators={[
              ...requiredFieldDefault,
              {
                validator: matchRegexp,
                params: [new RegExp(/^[1-9]\d+$/)],
                message: "Bitte das Format beachten (Nur Ziffern ohne führende Null)",
              },
            ]}
            error={!!_.get(errors, "fax")}
            helperText={_.get(errors, "fax")}
            disabled={recipientType !== "custom"}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Dokument"}
            name={"faxAttachment"}
            value={formValue(values, "faxAttachment")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!_.get(errors, "faxAttachment")}
            helperText={_.get(errors, "faxAttachment")}
          >
            <MenuItem value={"none"} disabled>
              {" "}
            </MenuItem>
            {_.map(mediaObjects, (mediaObject) => (
              <MenuItem
                key={mediaObject.id}
                value={mediaObject["@id"]}
                disabled={mediaObject.mimeType !== "application/pdf" || mediaObject.size > 2000000}
              >
                {mediaObject.description}
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
      </SendForm>
    </LegalbirdIoModal>
  );
}
