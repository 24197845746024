import documentTransformations from "./documentTransformations";
import { FormTransformations } from "../../../types/ProductService/ProductService";

const alimonyTransformations: FormTransformations = {
  dateFields: [
    { path: "dateSeparation", type: "date" },
    { path: "dateDivorce", type: "date" },
    { path: "dateMarriage", type: "date" },
  ],
  numberFields: [...documentTransformations.numberFields, "numberOfChildren"],
  timeFields: [...documentTransformations.timeFields],
  floatFields: [
    ...documentTransformations.floatFields,
    "alimonyPetitioner.netIncome",
    "alimonyPartner.netIncome",
    "alimonyPetitioner.otherIncome",
    "alimonyPartner.otherIncome",
    "alimonyPetitioner.livingExpenses",
    "alimonyPartner.livingExpenses",
    "alimonyPetitioner.otherExpenses",
    "alimonyPartner.otherExpenses",
    "insurance.deductible",
  ],
  ibanFields: [],
};
export default alimonyTransformations;
