import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { ProductSpecificFormDefinition } from "../../../types/ProductService/ProductService";

const employmentContractSpecificDefinition = (product: AbstractCase): ReturnType<ProductSpecificFormDefinition> => [
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({product}) => product.stage >= stagesCase.STAGE_CHECKED,
  },
]

export default employmentContractSpecificDefinition;
