import _ from "lodash";
import { transformToFormattedValue } from "../formServiceFunctions";
import { buildCalendlyLink, buildCalendlyLinkLawyer } from "../calendlyService";
import { getAdvoAssistRepresentative, getCaseLink, getServiceWorldCaseLink } from "../Product/ProductService";
import { listAddress, listAllClients, listPersonWithAddress } from "../formatterService";
import { AbstractCase } from "../../types/AbstractCase";
import { UseForm } from "../../types/UseForm";
import { Person } from "../../types/Person";
import { Address } from "../../types/Address";

const SPECIAL_PATHS_WITH_VALUE = ["customer.contactTime"];

export const fillTemplate = (templateString: string, productEntity: AbstractCase, formValues: UseForm["values"]) => {
  /*
  transform parameters into readable keys to check if they are present in the productEntity
  because the whole thing crashes if the method tries to replace a placeholder which doesnt exist,
  for example if someone puts <%= wurst.broetchen %> as a placeholder and there is no wurst object

  const parameterMatches = templateString.match(/<%=.+%>/g);
  const parameters = _.map(parameterMatches, match => {
    let isolatedParameter = _.trimStart(match, "<%=");
    isolatedParameter = _.trimEnd(isolatedParameter, "%>");
    return _.trim(isolatedParameter);
  });

  check if all keys exist in productEntity with _.every or something like that
  */

  const compiled = _.template(templateString);
  try {
    return compiled({
      [productEntity.productClassName]: productEntity,
      product: productEntity,
      formValues: formValues,
      translate,
      salutation,
      getCaseLink,
      getServiceWorldCaseLink,
      wrapInLinkTag,
      getWeRepresentAllClients
    });
  } catch (e) {
    console.error(e);
    return "Fehler in der Vorlage. Dies liegt höchstwahrscheinlich an einem falschen Platzhalter.";
  }
};

export const salutation = (product: AbstractCase, formValues: UseForm["values"]) => {
  if (!formValues?.recipientType) {
    return getPersonalSalutation(product.client);
  }

  if (_.startsWith(formValues.recipientType, "processParticipantsPeople")) {
    return getPersonalSalutation(_.get(product, _.replace(formValues.recipientType, ".email", "")));
  }

  switch (formValues.recipientType) {
    case "contractualPartnerLegalEntity":
      return product.contractualPartnerType === "contractualPartnerPerson"
        ? getPersonalSalutation(product.contractualPartnerPerson)
        : "Sehr geehrte Damen und Herren,";
    case "opponentLawFirm":
      return getLawyerSalutation(product.opponentLawyer);
    case "authority":
    case "prosecution":
    case "court":
      return "";
    case "customer":
    case "petitioner":
    case "client":
      return getAllClientsSalutation(product);
    case "advoAssistRepresentative":
      return getLawyerSalutation(getAdvoAssistRepresentative(product));
    case "debtCollectionAgency":
    case "insurance":
    case "custom":
    default:
      return "Sehr geehrte Damen und Herren,";
  }
};

export const getWeRepresentAllClients = (product: AbstractCase) => {

  let initialPart;

  if (product.clientWasMinorAtContractDate) {
    initialPart = `in vorbezeichneter Angelegenheit zeigen wir an, dass uns unsere minderjährige Mandantschaft ` +
      `${listPersonWithAddress(product.client, true)}, ` +
      `vertreten durch ${(product.clientLegalRepresentativeAmount === 2 && product.clientLegalRepresentativesAuthorizationType === "both") ? "ihre" : "ihren"}`
      + ` gesetzlichen Vertreter ${listPersonWithAddress(product.clientLegalRepresentative)} ` +
      `${(product.clientLegalRepresentativeAmount === 2 && product.clientLegalRepresentativesAuthorizationType === "both") ?
        `sowie ${listPersonWithAddress(product.secondClientLegalRepresentative)}`
        : ""}` +
      ` mit der Wahrnehmung und Vertretung ihrer rechtlichen Interessen beauftragt hat.`;

  } else {
    initialPart = `in vorbezeichneter Angelegenheit zeigen wir an, dass uns ${listAllClients(product)},` +
      ` wohnhaft ${listAddress(product.client.residenceAddress)}, zur Wahrnehmung und Vertretung ihrer rechtlichen Interessen beauftragt ${product.additionalClients.length > 0 ? "haben." : "hat."}`;
  }

  return initialPart + " " + (product.powerOfAttorneyDocument ?
    (`Eine auf uns lautende Vollmacht ${product.clientWasMinorAtContractDate ? "des gesetzlichen Vertreters " : ""}haben wir diesem Schreiben beigefügt.`) :
    "Unsere ordnungsgemäße Bevollmächtigung wird anwaltlich versichert.");
};
const getPersonalSalutation = (person: Person, firstLetterLowercase = false) => {
  if (!person) {
    return "";
  }
  return `${firstLetterLowercase ? "sehr" : "Sehr"} ${person.gender === "female" ? "geehrte Frau" : "geehrter Herr"} ${
    person.familyName
  },`;
};
const getAllClientsSalutation = (product: AbstractCase) => {
  if (product.additionalClients.length !== 1) {
    return getPersonalSalutation(product.client);
  }
  return `${getPersonalSalutation(product.client)} ${getPersonalSalutation(product.additionalClients[0], true)}`;
};
const getLawyerSalutation = (person: Person) => {
  if (!person) {
    return "";
  }
  const salutation = person.gender === "female" ? "Sehr geehrte Frau Kollegin" : "Sehr geehrter Herr Kollege";
  return salutation + " " + person.familyName + ",";
};

const generateCalendlyLinkLawyer = (product: AbstractCase) => {
  return wrapInLinkTag(buildCalendlyLinkLawyer(product), "Termin vereinbaren");
};

const generateCalendlyLinkRoundRobin = (product: AbstractCase) => {
  return wrapInLinkTag(buildCalendlyLink(product), "Termin vereinbaren");
};

const wrapInLinkTag = (url: string, label: string = "Hier klicken") => {
  return "<a href=" + url + "><u><strong>" + _.toUpper(label) + "</strong></u></a>";
};

const getRubrumContractLaw = (product: AbstractCase) => {
  switch (product.caseCategory) {
    case "defendClaim":
      return product.opponentVsClientString;
    default:
      return product.clientVsOpponentString;
  }
};

const getGenderedResponsibleLawyer = (product: AbstractCase) => {
  if (!product.backofficeCase.lawyer?.person?.gender) {
    return "";
  }

  return (product.backofficeCase.lawyer.person.gender === "male" ? "Rechtsanwalt " : "Rechtsanwältin ") +
    product.backofficeCase.lawyer.person.fullname;
};

const getAddressWithPostOfficeBoxFallback = (address: Address) => {
  if (address.streetAddress && address.postalCode && address.addressLocality) {
    return `${address.streetAddress}, ${address.postalCode} ${address.addressLocality}`;
  }
  if (address.postOfficeBoxNumber && address.postOfficeBoxPostalCode && address.postOfficeBoxAddressLocality) {
    return `Postfach: ${address.postOfficeBoxNumber}, ${address.postOfficeBoxPostalCode} ${address.postOfficeBoxAddressLocality}`;
  }
  return "Adressdaten nicht vollständig";
};

const handleSpecialPaths = (product: AbstractCase, path: string) => {
  switch (path) {
    case "rubrumContractLaw":
      return getRubrumContractLaw(product);
    case "genderedResponsibleLawyer":
      return getGenderedResponsibleLawyer(product);
    case "calendlyLink":
    case "calendlyLinkRoundRobin":
      return generateCalendlyLinkRoundRobin(product);
    case "calendlyLinkLawyer":
      return generateCalendlyLinkLawyer(product);
    case "appealRevokeLink":
      return wrapInLinkTag(
        getServiceWorldCaseLink(product) +
        "/einspruch-ruecknahme?&utm_source=legalbird&utm_medium=email&utm_campaign=feedback",
        "Jetzt Rückmeldung geben"
      );
    case "furtherProcessLink":
      return wrapInLinkTag(
        getServiceWorldCaseLink(product) + "/rueckmeldung?&utm_source=legalbird&utm_medium=email&utm_campaign=feedback",
        "Jetzt weiteres Vorgehen festlegen"
      );
    case "customer.contactTime":
      const value = _.get(product, path);
      return value === "flexible" ? "Ich bin flexibel" : "gegen " + value + " Uhr";
    case "weRepresentAllClients":
      return getWeRepresentAllClients(product);
    default:
      break;
  }

  if (path.startsWith("addressWithPostOfficeBoxFallback.")) {
    return getAddressWithPostOfficeBoxFallback(_.get(product, path.replace("addressWithPostOfficeBoxFallback.", "")));
  }

  return "";
};

const translate = (product: AbstractCase, path: string) => {
  let value = _.get(product, path);
  if (value === undefined || value === null || _.includes(SPECIAL_PATHS_WITH_VALUE, path)) {
    return handleSpecialPaths(product, path);
  }
  return transformToFormattedValue(product, path);
};
