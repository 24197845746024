import React from "react";
import { Box, Hidden, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../../CircleIcon/CircleIcon";
import { getMediaObjectSize } from "../../../../services/formServiceFunctions";
import MediaObjectChips from "../../../MediaObjectSection/MediaObjectChips";
import { getIcon } from "../../../../services/mediaObjectService";
import { MediaObjectHistoryLog } from "../../../../types/HistoryLog";
import moment from "moment";
import { getCreatedBy } from "../../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import { translate } from "../../../../services/Translations/translatorService";
import { useDocumentClassLabelAi } from "../../../../provider/DocumentClassLabelAiProvider";
import { useActionIdentifier } from "../../../../provider/ActionIdentifierProvider";
import { MediaObject } from "../../../../types/MediaObject";

type CaseHistoryMediaObjectProps = {
  historyLog: MediaObjectHistoryLog;
};

export default function CaseHistoryMediaObject({ historyLog }: CaseHistoryMediaObjectProps) {
  const { backofficeUsers } = useBackofficeUser();
  const { translateDocumentClassLabel, isLoading: isLoadingDocumentClassLabels } = useDocumentClassLabelAi();
  const { translateActionIdentifier, isLoading: isLoadingActionIdentifiers } = useActionIdentifier();

  const mediaObject: MediaObject & { identifier?: string } = JSON.parse(historyLog.mediaObject);

  const logDate = moment(historyLog.logDate);

  const getTextDecorationByType = () => {
    switch (historyLog.type) {
      case "mediaObjectDelete":
        return "line-through";
      case "mediaObject":
      default:
        return "none";
    }
  };

  const InfoText = () => {
    switch (historyLog.type) {
      case "mediaObjectDelete":
        return (
          <>
            Gelöscht durch {historyLog.backofficeUser?.person?.fullname || "Unbekannt"} am{" "}
            {logDate.format("DD.MM.YYYY")} um {logDate.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </>
        );
      case "mediaObjectIdentifierChange":
        if (isLoadingDocumentClassLabels || isLoadingActionIdentifiers) {
          return null;
        }
        return (
          <>
            {mediaObject.identifier && (
              <Typography>
                Art des Dokuments: {mediaObject.identifier} -{" "}
                {translate(`identifier.${mediaObject.product}.${mediaObject.identifier}.label`)}
              </Typography>
            )}
            {mediaObject.documentClassLabelAi && (
              <Typography>
                Dokumentenart: {mediaObject.documentClassLabelAi} -{" "}
                {translateDocumentClassLabel(mediaObject.product, mediaObject.documentClassLabelAi)}
              </Typography>
            )}
            {mediaObject.actionIdentifier && mediaObject.documentClassLabelAi && (
              <Typography>
                Aktionskenner: {mediaObject.actionIdentifier} -{" "}
                {translateActionIdentifier(mediaObject.documentClassLabelAi, mediaObject.actionIdentifier)}
              </Typography>
            )}
            Geändert durch {historyLog.backofficeUser?.person?.fullname || "Unbekannt"} am{" "}
            {logDate.format("DD.MM.YYYY")} um {logDate.format("HH:mm")} Uhr
          </>
        );
      case "mediaObject":
      default:
        return (
          <>
            Hinzugefügt durch {getCreatedBy(mediaObject.createdBy, backofficeUsers)} am {logDate.format("DD.MM.YYYY")}{" "}
            um {logDate.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </>
        );
    }
  };

  return (
    <>
      <Grid container wrap={"nowrap"}>
        <Hidden only={"xs"}>
          <Grid item sx={{ paddingRight: "2rem" }}>
            <CircleIcon icon={getIcon(mediaObject)} />
          </Grid>
        </Hidden>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  fontSize: "1rem",
                  textDecoration: getTextDecorationByType(),
                }}
              >
                {mediaObject.originalName}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ fontSize: "1rem" }}>
              {mediaObject.description}
            </Grid>
            <Grid item sx={{ paddingTop: 1 }}>
              <InfoText />
            </Grid>
            <Grid item xs={12}>
              <MediaObjectChips mediaObject={mediaObject} showFolder />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
