import React, { useMemo, useState } from "react";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { translate } from "../../../services/Translations/translatorService";
import { formValue, transformToFormValues } from "../../../services/formServiceFunctions";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import ValidatorDateField from "../../Validator/ValidatorDatePicker";
import _ from "lodash";
import InsurancePicker from "../../InsurancePicker/InsurancePicker";
import ContractLawRepresentatives from "../../ContractLawRepresentatives/ContractLawRepresentatives";
import CaseRejectionCategorySelect from "../../CaseRejectionCategorySelect/CaseRejectionCategorySelect";
import ProcessParticipantsPeopleForm from "./Elements/ProcessParticipantsPeopleForm";
import ProcessParticipantsOrganizationsForm from "./Elements/ProcessParticipantsOrganizationsForm";
import FormElementAlert from "./FormElementAlert";
import CourtPicker from "../../CourtPicker/CourtPicker";
import CaseEntityPicker from "../CaseEntityPicker/CaseEntityPicker";
import BeaLawyerImportButton from "./Elements/BeaLawyerImportButton";
import ExpandableTextField from "../../Validator/ExpandableTextField";
import ValidatorNumberField, { ValidatorNumberFieldProps } from "../../Validator/ValidatorNumberField";
import AdditionalClientsForm from "./Elements/AdditionalClientsForm";
import { ValidatorType } from "../../../types/ValidatorType";
import { UseForm } from "../../../types/UseForm";
import { AbstractCase } from "../../../types/AbstractCase";
import { SelectOption } from "../../../types/SelectOption";

export type FormElementProps = {
  type:
    | "ValidatorTextField"
    | "ValidatorSelect"
    | "ValidatorDateField"
    | "InsurancePicker"
    | "DebtCollectionAgencyPicker"
    | "ContractualPartnerLegalEntityPicker"
    | "AuthorityPicker"
    | "ProsecutionPicker"
    | "CourtPicker"
    | "ContractRepresentatives"
    | "CaseRejectionCategorySelect"
    | "ProcessParticipantsPeopleForm"
    | "ProcessParticipantsOrganizationsForm"
    | "EmptyElement"
    | "BeaLawyerImportButton"
    | "ExpandableTextField"
    | "ValidatorNumberField"
    | "AdditionalClientsForm";

  path: string;
  translationPath?: string | (({ product, values }: { product?: AbstractCase; values: UseForm["values"] }) => string);
  helperText?: string;
  isDisabled?: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) => boolean;
  options?: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) => SelectOption[];
  validators?: Array<ValidatorType>;
  isHidden?: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) => boolean;
  additionalProps?: Record<string, any>;
  label?: string;
  mandatoryFields?: Array<string>;
  product: AbstractCase;
  alert?: Record<string, any>;
  refreshPage?: Function;
  numberField?: "float"|"int";
} & Pick<ValidatorNumberFieldProps, "fieldType" | "numberType">;
export type FormElementPropsWithoutProduct = Omit<FormElementProps, "product">;

const FormElement = ({
  type,
  path,
  label,
  isDisabled = () => {
    return false;
  },
  isLoading,
  helperText,
  options = () => {
    return [];
  },
  validators,
  isHidden = () => {
    return false;
  },
  additionalProps = {},
  values,
  errors,
  handleChange,
  handleBlur,
  registerValidators,
  handleDateChange,
  mandatoryFields = [],
  product,
  alert,
  translationPath,
  refreshPage = () => {},
  fieldType,
  numberType,
}: FormElementProps & UseForm) => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const initialValues = useMemo(() => {
    return { [product?.productClassName || "base"]: transformToFormValues(product) };
  }, [product]);

  if (isHidden({ values, product })) {
    return null;
  }

  const handleBlurInternal: UseForm["handleBlur"] = (e) => {
    if (!alert) {
      handleBlur(e);
      return;
    }
    setIsShowAlert(true);
  };

  const getOptionLabel = (option: SelectOption, path: string) => {
    if (_.isString(option.label)) {
      return option.label;
    }

    if (option.value === "__null__") {
      return " ";
    }

    return translate(`${path}.values.${option.value}`);
  };

  if (_.isFunction(translationPath)) {
    translationPath = translationPath({ values });
  }

  const internalTranslationPath = translationPath || path;

  let formField;
  let initialValue = formValue(initialValues, path);
  switch (type) {
    case "ValidatorTextField":
      formField = (
        <ValidatorTextField
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path)}
          disabled={isLoading || isDisabled({ values, product })}
          isHighlighted={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          autoComplete={"none"}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          initialDependentValidationFields={[]}
          {...additionalProps}
        />
      );
      break;
    case "ValidatorNumberField":
      formField = (
        <ValidatorNumberField
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path)}
          disabled={isLoading || isDisabled({ values, product })}
          isHighlighted={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          autoComplete={"none"}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          fieldType={fieldType}
          numberType={numberType}
          {...additionalProps}
        />
      );
      break;
    case "ExpandableTextField":
      formField = (
        <ExpandableTextField
          dependentValidationFields={[]}
          maxRows={undefined}
          buttonLabel={undefined}
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path)}
          disabled={isLoading || isDisabled({ values, product })}
          isMandatory={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          autoComplete={"none"}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          {...additionalProps}
        />
      );
      break;
    case "ValidatorSelect":
      formField = (
        <ValidatorSelect
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path)}
          disabled={isLoading || isDisabled({ values, product })}
          isHighlighted={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          {...additionalProps}
        >
          {_.map(options({ values, product }), (option, index) => {
            return (
              // @ts-ignore ignored because we also have booleans in values
              <MenuItem key={index} value={option.value} disabled={!!option.disabled}>
                {getOptionLabel(option, internalTranslationPath)}
              </MenuItem>
            );
          })}
        </ValidatorSelect>
      );
      break;
    case "CaseRejectionCategorySelect":
      formField = (
        <CaseRejectionCategorySelect
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path)}
          disabled={isLoading || isDisabled({ values, product })}
          isHighlighted={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          {...additionalProps}
        >
          {_.map(options({ values, product }), (option, index) => {
            return (
              // @ts-ignore ignored because we also have booleans in values
              <MenuItem key={index} value={option.value} disabled={!!option.disabled}>
                {getOptionLabel(option, internalTranslationPath)}
              </MenuItem>
            );
          })}
        </CaseRejectionCategorySelect>
      );
      break;
    case "ValidatorDateField":
      initialValue = formValue(initialValues, path, undefined);
      formField = (
        <ValidatorDateField
          label={translate(`${internalTranslationPath}.label`, label)}
          name={path}
          value={formValue(values, path, undefined)}
          disabled={isLoading || isDisabled({ values, product })}
          isHighlighted={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          onChange={(date) => handleDateChange(date, path)}
          handleBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[path]}
          helperText={errors[path] || helperText}
          {...additionalProps}
        />
      );
      break;
    case "InsurancePicker":
      formField = (
        <InsurancePicker
          label={translate(`${internalTranslationPath}.label`)}
          name={`${path}.id`}
          value={formValue(values, `${path}.id`)}
          disabled={isLoading || isDisabled({ values, product })}
          isMandatory={mandatoryFields.includes(_.replace(path, product?.productClassName + ".", ""))}
          onChange={handleChange}
          onBlur={handleBlurInternal}
          registerValidators={registerValidators}
          validators={validators}
          error={!!errors[`${path}.id`]}
          helperText={errors[`${path}.id`] || helperText}
          {...additionalProps}
        />
      );
      break;
    case "ContractualPartnerLegalEntityPicker":
      formField = (
        <CaseEntityPicker
          path={_.replace(path, product?.productClassName + ".", "")}
          type={"contractualPartnerLegalEntity"}
          disabled={isLoading || isDisabled({ values, product })}
        />
      );
      break;
    case "DebtCollectionAgencyPicker":
      formField = (
        <CaseEntityPicker
          path={_.replace(path, product?.productClassName + ".", "")}
          type={"debtCollectionAgency"}
          disabled={isLoading || isDisabled({ values, product })}
        />
      );
      break;
    case "AuthorityPicker":
      formField = (
        <CaseEntityPicker
          path={_.replace(path, product?.productClassName + ".", "")}
          type={"authority"}
          disabled={isLoading || isDisabled({ values, product })}
        />
      );
      break;
    case "ProsecutionPicker":
      formField = (
        <CaseEntityPicker
          path={_.replace(path, product?.productClassName + ".", "")}
          type={"prosecution"}
          disabled={isLoading || isDisabled({ values, product })}
        />
      );
      break;
    case "CourtPicker":
      formField = (
        <CourtPicker disabled={isLoading || isDisabled({ values, product })} courtTypes={additionalProps.courtTypes} />
      );
      break;
    case "ContractRepresentatives":
      formField = (
        <ContractLawRepresentatives
          name={path}
          values={formValue(values, path, undefined)}
          disabled={isLoading || isDisabled({ values, product })}
          onChange={handleChange}
          {...additionalProps}
        />
      );
      break;
    case "ProcessParticipantsPeopleForm":
      formField = <ProcessParticipantsPeopleForm product={product} refreshPage={refreshPage} />;
      break;
    case "ProcessParticipantsOrganizationsForm":
      formField = <ProcessParticipantsOrganizationsForm product={product} refreshPage={refreshPage} />;
      break;
    case "BeaLawyerImportButton":
      formField = <BeaLawyerImportButton product={product} handleChange={handleChange} values={values} />;
      break;
    case "AdditionalClientsForm":
      formField = <AdditionalClientsForm product={product} refreshPage={refreshPage} />;
      break;
    default:
      return null;
  }

  return (
    <>
      {formField}
      {!!alert && (
        <FormElementAlert
          alert={alert}
          label={translate(`${internalTranslationPath}.label`, label)}
          initialValue={initialValue}
          currentValue={formValue(values, path)}
          path={path}
          handleChange={handleChange}
          isShowAlert={isShowAlert}
          setIsShowAlert={setIsShowAlert}
        />
      )}
    </>
  );
};

export default FormElement;
