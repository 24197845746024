import { FormTransformations } from "../../../types/ProductService/ProductService";

const settlementTransformations: FormTransformations = {
  dateFields: [
    { path: "insuranceCoverageDate", type: "date" },
    { path: "settlementReachedDate", type: "date" },
    { path: "settlementRevocationDate", type: "date" },
    { path: "dismissalProtectionSuitDeadline", type: "date" },
    { path: "petitioner.birthDate", type: "date" },
    { path: "employedSince", type: "date" },
    { path: "contractTerminationReceivedDate", type: "date" },
    { path: "contractTerminationDate", type: "date" },
    { path: "contractDate", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "finalJudgement", type: "date" },
    { path: "lastWorkDayCalculated", type: "date" },
    { path: "lastWorkDayCancellation", type: "date" },
    { path: "verification", type: "dateTime" },
  ],
  numberFields: [
    "leadStatus",
    "applicationStatus",
    "numberChildren",
    "numberOfEmployees",
    "responsibleCourt.advoAssistId",
  ],
  floatFields: ["grossSalary", "valueInDispute", "nWnFAmount", "insurance.deductible"],
  ibanFields: ["clientBankData.iban"],
};
export default settlementTransformations;
