import { useInvoiceTypeIdentifiers } from "../../../../provider/InvoiceTypeIdentifiersProvider";
import { InvoiceTypeIdentifierChangeHistoryLog } from "../../../../types/HistoryLog";
import GenericLogEntry from "../GenericLogEntry";
import { translate } from "../../../../services/Translations/translatorService";
import { Create } from "@mui/icons-material";
import { Box } from "@mui/material";
import moment from "moment";
import { cloneHistoryLogAsGeneric } from "../../../../services/historyService";

type CaseHistoryInvoiceTypeIdentifierChangeProps = {
  productClassName: string;
  historyItem: InvoiceTypeIdentifierChangeHistoryLog;
};

export default function CaseHistoryInvoiceTypeIdentifierChange({
  productClassName,
  historyItem,
}: CaseHistoryInvoiceTypeIdentifierChangeProps) {
  const { isLoading: isLoadingInvoiceTypeIdentifiers, translateInvoiceTypeIdentifier } = useInvoiceTypeIdentifiers();

  function InvoiceTypeDiff() {
    if (isLoadingInvoiceTypeIdentifiers) {
      return null;
    }
    return (
      <>
        Rechnung vom{" "}
        {!!historyItem.logData.invoiceDate
          ? moment(historyItem.logData.invoiceDate, ["DD.MM.YYYY", "YYYY.MM.DD"]).format("DD.MM.YYYY")
          : "unbekannt "}{" "}
        Belegnummer {!!historyItem.logData.invoiceNumber ? historyItem.logData.invoiceNumber + " | " : "unbekannt | "}
        {!!historyItem.logData.invoiceTypeIdentifier.previous
          ? translateInvoiceTypeIdentifier(productClassName, historyItem.logData.invoiceTypeIdentifier.previous)
          : "Leer "}
        {" >> "}
        {!!historyItem.logData.invoiceTypeIdentifier.current
          ? translateInvoiceTypeIdentifier(productClassName, historyItem.logData.invoiceTypeIdentifier.current)
          : "Leer"}
      </>
    );
  }

  return (
    <GenericLogEntry
      subject={translate("historyItem.entityDataChange.label")}
      Icon={Create}
      historyItem={cloneHistoryLogAsGeneric(historyItem)}
      info={
        <Box>
          <InvoiceTypeDiff />
        </Box>
      }
    />
  );
}
