import _ from "lodash";
import iconImage from "../assets/icon/collections-24px.svg";
import iconPdf from "../assets/icon/picture_as_pdf-24px.svg";
import iconAttachment from "../assets/icon/attach_file-24px.svg";
import { MediaObject } from "../types/MediaObject";
import { AbstractCase } from "../types/AbstractCase";
import { BackofficeUser } from "../types/BackofficeUser";

export const FILE_SIZE_LIMIT_WARNING = 10_485_760;
export const FILE_SIZE_LIMIT_EXCEEDED = 20_971_520;
export const FILE_NAME_LENGTH_LIMIT = 70;

export const filterByFolder = (mediaObjects: MediaObject[], folder: string) => {
  return _.filter(mediaObjects, (mediaObject) => {
    return !_.startsWith(mediaObject.electronicFileFolderPath ?? "", folder);
  });
};

export const getIcon = (mediaObject: MediaObject) => {
  switch (mediaObject.mimeType) {
    case "image/jpg":
    case "image/jpeg":
    case "image/png":
      return iconImage;
    case "application/pdf":
      return iconPdf;
    default:
      return iconAttachment;
  }
};

export const getMediaObjectFilters = (
  product: Pick<AbstractCase, "productClassName" | "id">,
  currentUser: BackofficeUser
) => {
  let mediaObjectsFilter: Partial<MediaObject> = {
    product: product.productClassName,
    productId: product.id,
  };

  if (currentUser.isExternal) {
    mediaObjectsFilter.showInExternalView = true;
  }
  return mediaObjectsFilter;
};

export const isValidUploadableFile = (fileName: string, checkLength: boolean = true) => {
  return !(/[ ‚{}()%&@#$~!^?*<>,\\+:=/“;[\]|]/.test(fileName) ||
    (fileName.match(/\./g) || []).length > 1 ||
    (checkLength && fileName.length > FILE_NAME_LENGTH_LIMIT));

};
