import * as status from "../LeadStatus/StatusDocument";
import { StageMetaData } from "../../types/ProductService/ProductService";

const STAGE_INCOMPLETE = 10;
const STAGE_READY = 20;
const STAGE_CHECKED = 30;
const STAGE_ORDERED = 40;
const STAGE_PAID = 50;
const STAGE_COMPLETED = 60;

export { STAGE_INCOMPLETE, STAGE_READY, STAGE_CHECKED, STAGE_ORDERED, STAGE_PAID, STAGE_COMPLETED };

export const stageMetaDataDocument: StageMetaData = {
  [STAGE_INCOMPLETE]: {
    label: "Unvollständig",
    stage: STAGE_INCOMPLETE,
    leadStatus: null,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_READY]: {
    label: "Daten befüllt",
    stage: STAGE_READY,
    leadStatus: status.STATUS_DATA_COMPLETED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Daten überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_ORDERED]: {
    label: "Gekauft",
    stage: STAGE_ORDERED,
    leadStatus: status.STATUS_ORDERED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_PAID]: {
    label: "Bezahlt",
    stage: STAGE_PAID,
    leadStatus: status.STATUS_PAID,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Dokument generiert",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
};
