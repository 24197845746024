import { AbstractCase } from "../../../../types/AbstractCase";
import { UseForm } from "../../../../types/UseForm";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";

export default function personFields({
  path,
  isDisabled = () => false,
  isHidden = () => false,
  showDiverseGenderOption = false,
}: {
  path: string;
  isDisabled?: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) => boolean;
  isHidden?: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) => boolean;
  showDiverseGenderOption?: boolean;
}): FormElementPropsWithoutProduct[] {
  return [
    {
      type: "ValidatorSelect",
      path: `${path}.gender`,
      isDisabled: isDisabled,
      isHidden: isHidden,
      options: () => [
        { value: "male" },
        { value: "female" },
        ...(showDiverseGenderOption ? [{ value: "diverse" }] : []),
      ],
    },
    {
      type: "ValidatorTextField",
      path: `${path}.givenName`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
    {
      type: "ValidatorTextField",
      path: `${path}.familyName`,
      isDisabled: isDisabled,
      isHidden: isHidden,
    },
  ];
}
