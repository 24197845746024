import React from "react";
import { LeadStatusHistoryLog } from "../../../../types/HistoryLog";
import { translate } from "../../../../services/Translations/translatorService";
import { ArrowRightAlt } from "@mui/icons-material";
import GenericLogEntry from "../GenericLogEntry";
import _ from "lodash";
import { getStageList } from "../../../../services/Product/ProductService";
import { cloneHistoryLogAsGeneric } from "../../../../services/historyService";
import { AbstractCase } from "../../../../types/AbstractCase";

type CaseHistoryLeadStatusChangeProps = {
  historyItem: LeadStatusHistoryLog;
  productClassName: AbstractCase["productClassName"];
};

export default function CaseHistoryLeadStatusChange({ historyItem, productClassName }: CaseHistoryLeadStatusChangeProps) {

  const translatedCurrentStage = _.find(
    getStageList(productClassName),
    (stage) => stage.leadStatus === historyItem.logData.leadStatus.current
  )?.label;
  const translatedPreviousStage = _.find(
    getStageList(productClassName),
    (stage) => stage.leadStatus === historyItem.logData.leadStatus.previous
  )?.label;

  return (
    <GenericLogEntry
      subject={translate("historyItem.entityStatusChange.label")}
      Icon={ArrowRightAlt}
      info={
        <div>
          {translatedCurrentStage || "Leer"} {" >> "}
          {translatedPreviousStage || "Leer"}
        </div>
      }
      historyItem={cloneHistoryLogAsGeneric(historyItem)}
    />
  );
};
