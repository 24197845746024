import React, { useEffect, useMemo } from "react";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { Box, Checkbox, Grid, ListItemText, Typography } from "@mui/material";
import { useCommunicationMedium } from "./CommunicationMediumProvider";
import { getBeaSafeId, getSubject, isValidBeaMediaObject } from "./communicationHelperFunctions";
import SendForm from "./SendForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { formValue } from "../../../services/formServiceFunctions";
import { beaSafeIdFieldDefault, requiredFieldDefault } from "../../../services/validationRules";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import useForm from "../../../hooks/useForm";
import ApiClient from "../../../services/ApiClient";
import { useSnackbar } from "notistack";
import { MediaObject } from "../../../types/MediaObject";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function BeaSend() {
  const { product, open, handleClose, recipientType, externalReference, externalReferenceIsEditable } =
    useCommunicationMedium();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };
  const { data: mediaObjectsCollection } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const mediaObjects = _.get(mediaObjectsCollection, "hydra:member");
  const { enqueueSnackbar } = useSnackbar();

  const beaSafeId = useMemo(() => {
    return getBeaSafeId(product, recipientType);
  }, [product, recipientType]);

  const onSubmit = async ({ values }: { values: any }) => {
    let payload = {
      beaSafeId: values.beaSafeId,
      referenceSender: product.reference,
      referenceRecipient: values.externalReference,
      subject: values.subject,
      backofficeCase: product?.backofficeCase?.id,
      createdBy: currentUser["@id"],
      legalDocuments: values.legalDocuments,
      attachments: values.attachments,
      forceSend: true,
    };

    if (recipientType === "opponentLawyer" && !product.opponentLawFirm.beaSafeId) {
      await ApiClient.put(product.opponentLawFirm["@id"], {
        body: JSON.stringify({ beaSafeId: values.beaSafeId }),
      });
    }

    try {
      await ApiClient.post("bea/send", {
        body: JSON.stringify(payload),
      });
      enqueueSnackbar("beA-Nachricht wurde erfolgreich versendet", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e: any) {
      const response = _.get(e, "response");
      const errorMessage =
        response?.errorMessage ||
        "Die beA-Nachricht konnte nicht versendet werden. Bitte Fristen prüfen und ggf. per Fax versenden. Erneuter Versand wird von System stündlich neu versucht";
      const additionalMessages = response?.additionalMessages || [];

      enqueueSnackbar(
        <Box textAlign={"left"}>
          <Typography variant={"h5"}>{errorMessage}</Typography>
          {additionalMessages.length > 0 && (
            <ul>
              {_.map(additionalMessages, (additionalMessage, index) => (
                <li key={index}>{additionalMessage}</li>
              ))}
            </ul>
          )}
        </Box>,
        {
          variant: "custom",
          isNonInteractive: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          persist: true,
        }
      );
    }
    await queryClient.invalidateQueries(queryKeys.collection("bea_messages", { case: product.backofficeCase.id }));
    handleClose();
  };

  const initialValues = {
    beaSafeId: "",
    externalReference: "",
    attachments: [],
    legalDocuments: [],
    subject: getSubject(product),
  };

  const { values, handleChange, handleBlur, handleSubmit, isLoading, registerValidators, errors } = useForm({
    initialValues,
    onSubmit,
    identifier: "" + product.id,
    clearFormAfterSubmit: true,
  });

  useEffect(() => {
    handleChange({
      target: {
        name: "beaSafeId",
        value: beaSafeId,
      },
    });
  }, [beaSafeId]);

  useEffect(() => {
    handleChange({
      target: {
        name: "externalReference",
        value: externalReference,
      },
    });
  }, [externalReference]);

  const possibleAttachments = _.filter(mediaObjects, (mediaObject) => {
    return !_.find(_.get(values, "legalDocuments"), (legalDocument) => legalDocument === mediaObject.id);
  });

  const possibleLegalDocuments = _.filter(mediaObjects, (mediaObject) => {
    return !_.find(_.get(values, "attachments"), (attachment) => attachment === mediaObject.id);
  });

  const getDescriptionFromMediaobjectId = (id: number) => {
    return _.find(mediaObjects, (mediaObject) => mediaObject.id === id).description;
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      disableBackdropClick
      title={"beA versenden"}
      submitButton={
        <ButtonLoading type={"submit"} variant={"contained"} onClick={handleSubmit} isLoading={isLoading}>
          Nachricht Versenden
        </ButtonLoading>
      }
    >
      <SendForm>
        {beaSafeId === "" && (
          <Grid item xs={12}>
            <ValidatorTextField
              onChange={handleChange}
              onBlur={handleBlur}
              name={"beaSafeId"}
              value={formValue(values, "beaSafeId")}
              label={"beA-Adresse Empfänger"}
              registerValidators={registerValidators}
              validators={[...requiredFieldDefault, ...beaSafeIdFieldDefault]}
              error={!!_.get(errors, "beaSafeId")}
              helperText={_.get(errors, "beaSafeId")}
              isHighlighted={false}
              initialDependentValidationFields={[]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!externalReferenceIsEditable}
            name={"externalReference"}
            value={formValue(values, "externalReference")}
            label={"Aktenzeichen Empfänger"}
            isHighlighted={false}
            registerValidators={() => {}}
            validators={[]}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"subject"}
            value={formValue(values, "subject")}
            label={"Betreff"}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault]}
            error={!!_.get(errors, "subject")}
            helperText={_.get(errors, "subject")}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Schriftsatz"}
            name={"legalDocuments"}
            value={formValue(values, "legalDocuments")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!_.get(errors, "legalDocuments")}
            helperText={_.get(errors, "legalDocuments")}
            multiple
            renderValue={(selected: Array<number>) =>
              _.map(selected, (mediaObjectId) => getDescriptionFromMediaobjectId(mediaObjectId)).join(", ")
            }
          >
            {_.map(possibleLegalDocuments, (mediaObject: MediaObject) => (
              <MenuItem key={mediaObject.id} value={mediaObject.id} disabled={!isValidBeaMediaObject(mediaObject)}>
                <Checkbox
                  checked={
                    !!_.find(
                      formValue(values, "legalDocuments"),
                      (selectedMediaObject) => selectedMediaObject === mediaObject.id
                    )
                  }
                />
                <ListItemText primary={mediaObject.description} />
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Anhang"}
            name={"attachments"}
            value={formValue(values, "attachments")}
            onChange={handleChange}
            onBlur={handleBlur}
            multiple
            renderValue={(selected: Array<number>) =>
              _.map(selected, (mediaObjectId) => getDescriptionFromMediaobjectId(mediaObjectId)).join(", ")
            }
          >
            {_.map(possibleAttachments, (mediaObject: MediaObject) => (
              <MenuItem key={mediaObject.id} value={mediaObject.id} disabled={!isValidBeaMediaObject(mediaObject)}>
                <Checkbox
                  checked={
                    !!_.find(
                      formValue(values, "attachments"),
                      (selectedMediaObject) => selectedMediaObject === mediaObject.id
                    )
                  }
                />
                <ListItemText primary={mediaObject.description} />
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
      </SendForm>
    </LegalbirdIoModal>
  );
}
