import contractTransformations from "./contractTransformations";
import { FormTransformations } from "../../../types/ProductService/ProductService";

const dataLeakTransformations: FormTransformations = {
  dateFields: [
    { path: "crifDataCopyDate", type: "date" },
    { path: "crifPositiveDataDate", type: "date" },
    { path: "crifDataCopyCheckedNegativeDate", type: "date" },
    { path: "schufaDataCopyCheckedNegativeDate", type: "date" },
    { path: "schufaDataCopyDate", type: "date" },
    { path: "schufaPositiveDataDate", type: "date" },
    ...contractTransformations.dateFields!,
  ],
  numberFields: contractTransformations.numberFields,
  timeFields: contractTransformations.timeFields,
  floatFields: [
    "declaratoryMotionAmount",
    "injunctiveReliefAmount",
    "compensationAmount",
    ...contractTransformations.floatFields!,
  ],
  ibanFields: contractTransformations.ibanFields,
};
export default dataLeakTransformations;
