/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { useHolidays } from "../../provider/HolidayProvider";
import { useSnackbar } from "notistack";
import DateChangeButtonsToolbar from "./DateChangeButtonsToolbar";
import { Moment } from "moment";

type HolidayAwareDateChangeButtonsToolbarProps = {
  onChange: Function;
};

export default function HolidayAwareDateChangeButtonsToolbar({ onChange }: HolidayAwareDateChangeButtonsToolbarProps) {
  // At the moment every Page implements the HolidaysProvider so this should be no problem
  const { holidays } = useHolidays();
  const { enqueueSnackbar } = useSnackbar();

  const isHoliday = (date: Moment) => {
    return holidays.includes(date.format("YYYY-MM-DD"));
  };

  const getValidDate = (date: Moment) => {
    date = date.clone();

    while (date.day() === 6 || date.day() === 0 || isHoliday(date)) {
      date.add("1", "day");
    }

    return date;
  };

  const handleChange = (date: Moment) => {
    const setDate = getValidDate(date);
    onChange(setDate);

    if (!setDate.isSame(date)) {
      enqueueSnackbar("Datum automatisch angepasst, da das ausgewählte Datum ein Feiertag ist.", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  return <DateChangeButtonsToolbar onChange={handleChange} />;
}
