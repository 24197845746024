import React, { JSXElementConstructor, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import validatorStyle from "./validatorStyle";
import { DatePicker, DatePickerToolbarProps } from "@mui/x-date-pickers";
import { ValidatorType } from "../../types/ValidatorType";
import moment, { Moment } from "moment";
import { UseForm } from "../../types/UseForm";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";

type ValidatorDateFieldProps = {
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<string>;
  label: string;
  format?: string;
  name: string;
  helperText?: string;
  isHighlighted?: boolean;
  toolbar?: JSXElementConstructor<DatePickerToolbarProps<Moment>>;
  error?: boolean;
} & Pick<UseForm, "registerValidators" | "handleBlur"> &
  Omit<DatePickerProps<Moment>, "renderInput">;

function ValidatorDateField({
  name,
  value,
  helperText,
  registerValidators,
  validators,
  dependentValidationFields = [],
  isHighlighted = false,
  handleBlur,
  toolbar,
  error = false,
  format = "DD.MM.YYYY",
  ...rest
}: ValidatorDateFieldProps) {
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, validators, dependentValidationFields, name]);

  return (
    <FormControl fullWidth={true} sx={isHighlighted && !value ? validatorStyle.mandatory : undefined} error={error}>
      <DatePicker
        {...rest}
        value={value ? moment(value) : null}
        onAccept={(date) => {
          if (!handleBlur || !date) {
            return;
          }
          handleBlur({ target: { name: name, value: moment(date) } });
        }}
        slots={{ toolbar: toolbar }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default ValidatorDateField;
