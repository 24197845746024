import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageContentContainer from "../../Container/PageContentContainer";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import CustomerPageContent from "../../CustomerPageContent/CustomerPageContent";
import ApiClient from "../../../services/ApiClient";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/ReactQuery/reactQueryService";

export default function CustomerPage() {
  const { customerId } = useParams();
  const customerIdInt = parseInt(customerId || "");

  const [backofficeCases, setBackofficeCases] = useState([]);
  const navigate = useNavigate();

  const { data: customer } = useQuery(queryKeys.item("customers", customerIdInt), () =>
    fetchResource("customers", customerIdInt)
  );

  useEffect(() => {
    if (!customer) {
      return;
    }
    ApiClient.get("backoffice_cases?customerEmail=" + encodeURIComponent(customer.email)).then((result) =>
      setBackofficeCases(result["hydra:member"])
    );
  }, [customer]);

  useEffect(() => {
    if (backofficeCases.length > 0) {
      navigate(getCaseLinkByBackofficeCase(backofficeCases[0]) || "/");
    }
  }, [backofficeCases]);

  if (!customer || !backofficeCases) {
    return <LegalbirdLoader centered />;
  }

  return (
    <PageContentContainer>
      <CustomerPageContent customer={customer} />
    </PageContentContainer>
  );
}
