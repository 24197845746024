import _ from "lodash";
import { getProductData } from "./Product/ProductService";
import { convertToFormattedTime, insurances } from "./formServiceFunctions";
import moment from "moment";
import { HistoryLog } from "../types/HistoryLog";
import { AbstractCase } from "../types/AbstractCase";

const getInsuranceIdFromIri = (iri: string) => {
  return _.last(iri.split("/"));
};

export const prepareHistory = (history: HistoryLog[], productClassName: AbstractCase["productClassName"]): HistoryLog[] => {
  const historyCopy: HistoryLog[] = [];

  // unpack entityDataChange history log entries

  for (const key in history) {
    const historyLog: HistoryLog = _.cloneDeep(history[key]);
    if (historyLog.type !== "entityDataChange") {
      historyCopy.push(historyLog);
      continue;
    }
    if (Object.keys(historyLog.logData).includes("preventAllSystemEmails")) {
      historyCopy.push({ ..._.cloneDeep(historyLog), type: "mailPause" });
      delete historyLog.logData["preventAllSystemEmails"];
    }
    if (Object.keys(historyLog.logData).includes("preventAccounting")) {
      historyCopy.push({ ..._.cloneDeep(historyLog), type: "accountingPause" });
      delete historyLog.logData["preventAccounting"];
    }
    if (Object.keys(historyLog.logData).some((key) => !["preventAccounting", "mailPause"].includes(key))) {
      historyCopy.push(historyLog);
    }
  }
  _.forEach(historyCopy, (historyItem) => {
    _.forEach(historyItem.logData, (logDataEntry: any, key) => {
      if (!logDataEntry || !logDataEntry.current || !logDataEntry.previous) {
        return;
      }
      //convert dates
      if (
        _.find(
          getProductData(productClassName, "formTransformations.dateFields"),
          (dateField) => dateField.path === key
        )
      ) {
        logDataEntry.current = logDataEntry.current
          ? moment(logDataEntry.current).format("DD.MM.YYYY")
          : logDataEntry.current;
        logDataEntry.previous = logDataEntry.previous
          ? moment(logDataEntry.previous).format("DD.MM.YYYY")
          : logDataEntry.previous;
      }

      //convert insurances
      //works as long as insurance has this path in all products
      if (key === "insurance.insurance") {
        if (logDataEntry.current) {
          const currentInsurance = _.find(
            insurances,
            (insurance) => insurance.id + "" === getInsuranceIdFromIri(logDataEntry.current)
          );
          logDataEntry.current = currentInsurance ? currentInsurance.label : logDataEntry.current;
        }
        if (logDataEntry.previous) {
          const previousInsurance = _.find(
            insurances,
            (insurance) => insurance.id + "" === getInsuranceIdFromIri(logDataEntry.previous)
          );
          logDataEntry.previous = previousInsurance ? previousInsurance.label : logDataEntry.previous;
        }
      }

      //convert times
      if (
        _.find(getProductData(productClassName, "formTransformations.timeFields"), (timeField) => timeField === key)
      ) {
        logDataEntry.current = convertToFormattedTime(logDataEntry.current);
        logDataEntry.previous = convertToFormattedTime(logDataEntry.previous);
      }
    });
  });
  return historyCopy;
};

export function cloneHistoryLogAsGeneric<T>(historyLog: T): Omit<T, "type"> & { type: "generic" } {
  return { ...historyLog, type: "generic" };
}
