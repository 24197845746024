/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Box, Typography, type TypographyVariant } from "@mui/material";

const TypographyVariants = () => {
  const typographyVariants: TypographyVariant[] = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
  ];

  return (
    <>
      <Box mt={4}>
        <Typography variant="h2" gutterBottom>
          Typography Variants
        </Typography>
        {typographyVariants.map((variant) => (
          <Box>
            <Typography key={variant} variant={variant} gutterBottom>
              {variant} – The quick brown fox jumps over the lazy dog
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default TypographyVariants;
