import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { ProductSpecificFormDefinition } from "../../../types/ProductService/ProductService";

const travelProductSpecificDefinition = (product: AbstractCase): ReturnType<ProductSpecificFormDefinition> => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "cancelled" },
      { value: "participationImpossible" },
      { value: "notAsAdvertised" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.bookingDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.journeyStart`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.journeyEnd`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.contractualPartnerHasCancelled`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.reasonForDispute !== "cancelled",
    options: () => trueFalseDefaultOptions(`${product.productClassName}.contractualPartnerHasCancelled`),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.contractualPartnerCancellationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.reasonForDispute !== "cancelled" || !product.contractualPartnerHasCancelled,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.buyerPaidStatus`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "fully" }, { value: "partially" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.buyerPaidDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.buyerPaidAmount`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    fieldType: "money",
    numberType: "float",
  },
];
export default travelProductSpecificDefinition;
