import React, { useState } from "react";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import makeStyles from "@mui/styles/makeStyles";
import courtAppointmentStyle from "./courtAppointmentStyle";
import {
  dateFieldDefault,
  emailDefault,
  requiredFieldDefault,
} from "../../services/validationRules";
import _ from "lodash";
import FormElement, { FormElementPropsWithoutProduct } from "../Case/CaseForm/FormElement";
import SubmitButton from "../Button/SubmitButton";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import { Grid } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import AdvoAssistData from "../AdvoAssist/AdvoAssistData";
import moment from "moment";
import AdvoAssistActions from "../AdvoAssist/AdvoAssistActions";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import ContentBox from "../ContentBox/ContentBox";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import { apiPost } from "../../services/Api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../types/AbstractCase";
import { CourtAppointment as CourtAppointmentType } from "../../types/CourtAppointment";
import { UseForm } from "../../types/UseForm";
import { MediaObject } from "../../types/MediaObject";
import { SelectOption } from "../../types/SelectOption";
import * as stagesCase from "../../services/Stages/StagesCase";

// @ts-ignore
const useStyles = makeStyles(courtAppointmentStyle);

type CourtAppointmentProps = {
  headline: string;
  product: AbstractCase;
  courtAppointment: CourtAppointmentType;
  refreshPage: Function;
};

export default function CourtAppointment({ headline, product, courtAppointment, refreshPage }: CourtAppointmentProps) {
  const [mediaObjectToPreview, setMediaObjectToPreview] = useState<MediaObject>();
  const [isOpen, setIsOpen] = useState("");
  const updateMutation = useMutation(updateResource);
  const classes = useStyles();

  const getCourtDateTypeOptions = (product: AbstractCase): SelectOption[] => {
    switch (product.productCategory) {
      case "tenancyLaw":
      case "contractLaw":
        return [{ value: "hearing" }, { value: "hearingOfEvidence" }];
      case "employmentLaw":
        return [{ value: "settlement" }, { value: "trialEmploymentLaw" }];
      case "familyLaw":
        return [{ value: "divorce" }];
      case "trafficLaw":
        return [{ value: "trialTrafficLaw" }];
      default:
        return [];
    }
  };

  const initialValues = courtAppointment;
  const onSubmit = async ({ values }: UseForm["values"]) => {
    if (courtAppointment.courtDate?.id) {
      await updateMutation.mutateAsync({
        id: courtAppointment.courtDate.id,
        uri: "appointments",
        data: {
          appointmentDate: moment(formValue(values, "courtDate.appointmentDate")).format("YYYY-MM-DD"),
          appointmentTime: formValue(values, "courtDate.appointmentTime"),
        },
      });
    }

    //must happen before the put on court_appointments because a subscriber is listening that needs the person data
    if (courtAppointment.advoAssistIndependentLawyer && formValue(values, "attendeeType") === "individual") {
      await updateMutation.mutateAsync({
        id: courtAppointment.advoAssistIndependentLawyer.id,
        uri: "people",
        data: {
          gender: values.advoAssistIndependentLawyer.gender,
          givenName: values.advoAssistIndependentLawyer.givenName,
          familyName: values.advoAssistIndependentLawyer.familyName,
          email: values.advoAssistIndependentLawyer.email,
        },
      });
    }
    if (courtAppointment?.id) {
      await updateMutation.mutateAsync({
        id: courtAppointment.id,
        uri: "court_appointments",
        data: {
          courtDateType: values.courtDateType,
          attendeeType: values.attendeeType,
          customNotes: values.customNotes,
          personalAppearanceClient: values.personalAppearanceClient,
        },
      });
    }

    if (formValue(values, "attendeeType") === "advoAssist") {
      await apiPost("services/advo_assist/add_court_appointment/0", {
        courtAppointmentId: courtAppointment.id,
        appointmentType: values.courtDateType,
      });
    }

    refreshPage();
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: courtAppointment,
  });

  const formDefinition: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorDateField",
      path: "courtDate.appointmentDate",
      translationPath: "courtAppointment.courtDate.appointmentDate",
      validators: [
        ...dateFieldDefault,
        ...requiredFieldDefault,
      ],
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorTextField",
      path: "courtDate.appointmentTime",
      translationPath: "courtAppointment.courtDate.appointmentTime",
      validators: requiredFieldDefault,
      isDisabled: () => !!courtAppointment.advoAssist,
      additionalProps: {
        type: "time",
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    {
      type: "ValidatorSelect",
      path: "courtDateType",
      translationPath: "courtAppointment.courtDateType",
      validators: requiredFieldDefault,
      options: ({ product }: { product: AbstractCase }) => getCourtDateTypeOptions(product),
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorSelect",
      path: "attendeeType",
      translationPath: "courtAppointment.attendeeType",
      validators: requiredFieldDefault,
      options: () => [{ value: "advoAssist" }, { value: "individual" }],
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorSelect",
      path: "advoAssistIndependentLawyer.gender",
      translationPath: "courtAppointment.advoAssistIndependentLawyer.gender",
      validators: requiredFieldDefault,
      options: () => [{ value: "male" }, { value: "female" }],
      isHidden: ({ values }: UseForm["values"]) => formValue(values, "attendeeType") !== "individual",
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorTextField",
      path: "advoAssistIndependentLawyer.givenName",
      translationPath: "courtAppointment.advoAssistIndependentLawyer.givenName",
      validators: requiredFieldDefault,
      isHidden: ({ values }: UseForm["values"]) => formValue(values, "attendeeType") !== "individual",
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorTextField",
      path: "advoAssistIndependentLawyer.familyName",
      translationPath: "courtAppointment.advoAssistIndependentLawyer.familyName",
      validators: requiredFieldDefault,
      isHidden: ({ values }: UseForm["values"]) => formValue(values, "attendeeType") !== "individual",
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorTextField",
      path: "advoAssistIndependentLawyer.email",
      translationPath: "courtAppointment.advoAssistIndependentLawyer.email",
      validators: [...requiredFieldDefault, ...emailDefault],
      isHidden: ({ values }: UseForm["values"]) => formValue(values, "attendeeType") !== "individual",
      isDisabled: () => !!courtAppointment.advoAssist,
    },
    {
      type: "ValidatorSelect",
      translationPath: "courtAppointment.personalAppearanceClient",
      path: `personalAppearanceClient`,
      isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) > stagesCase.STAGE_TRIAL,
      options: () => [{ value: "ordered" }, { value: "yes" }, { value: "no" }],
    },
    {
      type: "ValidatorTextField",
      path: "customNotes",
      translationPath: "courtAppointment.customNotes",
      helperText:
        "Bei AdvoAssist wird der Inhalt ins Feld “Beschreibung” geschrieben. Bei “Individueller Anwalt” wird der Inhalt ans Ende des TV-Briefings eingefügt.",
      isDisabled: () => !!courtAppointment.advoAssist,
      additionalProps: {
        multiline: true,
        fullWidth: true,
      },
    },
  ];

  const deleteCourtAppointment = async () => {
    if (!!courtAppointment.advoAssist) {
      return;
    }
    await ApiClient.delete(courtAppointment["@id"]);
    refreshPage();
  };

  const confirmationDateTimeString = `${moment(formValue(useFormProps.values, "courtDate.appointmentDate")).format(
    "DD.MM.YYYY"
  )} um ${formValue(useFormProps.values, "courtDate.appointmentTime")} Uhr`;

  return (
    <>
      <ContentBox headline={headline}>
        {!!courtAppointment.invitationCourtDocument && (
          <div
            className={classes.linked}
            onClick={() =>
              courtAppointment.invitationCourtDocument &&
              setMediaObjectToPreview(courtAppointment.invitationCourtDocument)
            }
          >
            {courtAppointment.invitationCourtDocument.description}
          </div>
        )}
        <Grid container>
          {_.map(formDefinition, (formElementProps) => {
            const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;
            return (
              <Grid className={classes.spacingRight} item md={!fullWidth && 6} xs={12} key={formElementProps.path}>
                <FormElement product={product} {...formElementProps} {...useFormProps} />
              </Grid>
            );
          })}
          {!courtAppointment.advoAssist && (
            <>
              <Grid item xs={12} className={classes.spacingBottom} />
              <Grid item md={6} xs={12} className={classes.spacingRight}>
                <Button onClick={() => setIsOpen("delete")} fullWidth color="primary" variant="text">
                  Löschen
                </Button>
                <LegalbirdIoConfirm
                  handleClose={() => setIsOpen("")}
                  open={isOpen === "delete"}
                  handleConfirm={deleteCourtAppointment}
                  confirmText="Löschen"
                  headline="Terminelement löschen"
                  content='Wenn das Terminelement versehentlich erstellt wurde, kann es mit dem Klick auf "Löschen" gelöscht werden.'
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.spacingRight}>
                <SubmitButton
                  onClick={() => setIsOpen("submit")}
                  error={_.keys(useFormProps.errors).length > 0}
                  isLoading={useFormProps.isLoading}
                  disabled={_.keys(useFormProps.touchedValues).length === 0}
                  variant={"contained"}
                  type={"submit"}
                >
                  Speichern
                </SubmitButton>
                <LegalbirdIoConfirm
                  handleClose={() => setIsOpen("")}
                  open={isOpen === "submit"}
                  handleConfirm={useFormProps.handleSubmit}
                  confirmText={
                    formValue(useFormProps.values, "attendeeType") === "individual"
                      ? "Speichern"
                      : "Speichern und ausschreiben"
                  }
                  headline={
                    formValue(useFormProps.values, "attendeeType") === "individual"
                      ? "Gerichtstermin anlegen"
                      : "Gerichtstermin ausschreiben"
                  }
                  content={
                    formValue(useFormProps.values, "attendeeType") === "individual"
                      ? `Nach dem Klick auf “Speichern” wird zwar ein Gerichtstermin erstellt, dieser aber
                    NICHT bei AdvoAssist ausgeschrieben. WICHTIG: Diese Option ist nur für die Wahrnehmung von Gerichtsterminen
                    durch unsere internen Anwälte oder Kooperationsanwälten gedacht. Möchten Sie einen Gerichtstermin für
                    den ${confirmationDateTimeString} speichern?`
                      : `Nach dem Klick auf “Speichern und Ausschreiben” wird automatisch ein Gerichtstermin bei AdvoAssist
                   ausgeschrieben. Möchten Sie einen Gerichtstermin für den ${confirmationDateTimeString} ausschreiben?`
                  }
                />
              </Grid>
            </>
          )}

          {!!courtAppointment.advoAssist && !!courtAppointment.courtDate && (
            <>
              <AdvoAssistData
                advoAssist={courtAppointment.advoAssist}
                legalbirdAppointment={courtAppointment.courtDate}
              />
              <Grid item xs={12} className={classes.spacingBottom} />
              <AdvoAssistActions
                advoAssist={courtAppointment.advoAssist}
                legalbirdAppointment={courtAppointment.courtDate}
                product={product}
                refreshPage={refreshPage}
              />
            </>
          )}
          <Grid item md={6} xs={12} sx={{ padding: "0.5rem" }}>
            <Button
              sx={{ marginTop: 1 }}
              variant={"contained"}
              component={Link}
              to={getCaseLink(product) + "/handakte/" + courtAppointment.id}
              disabled={
                !!courtAppointment.courtDate?.appointmentDateTime
                  ? moment(courtAppointment.courtDate.appointmentDateTime) < moment()
                  : false
              }
            >
              Handakte bearbeiten
            </Button>
          </Grid>
        </Grid>
      </ContentBox>
      <DisplayMediaObject mediaObject={mediaObjectToPreview} closeDialog={() => setMediaObjectToPreview(undefined)} />
    </>
  );
}
