import { InvoiceReassignHistoryLog } from "../../../../types/HistoryLog";
import GenericLogEntry from "../GenericLogEntry";
import { translate } from "../../../../services/Translations/translatorService";
import { PointOfSaleOutlined } from "@mui/icons-material";
import { Link as LinkMui, Typography } from "@mui/material";
import { getCreatedBy } from "../../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import { getCaseLink } from "../../../../services/Product/ProductService";
import { Link } from "react-router-dom";
import moment from "moment";
import { cloneHistoryLogAsGeneric } from "../../../../services/historyService";

type CaseHistoryInvoiceReassignProps = {
  historyItem: InvoiceReassignHistoryLog;
};

export default function CaseHistoryInvoiceReassign({ historyItem }: CaseHistoryInvoiceReassignProps) {
  const { backofficeUsers } = useBackofficeUser();

  return (
    <GenericLogEntry
      subject={translate("historyItem.invoiceReassign.label")}
      Icon={PointOfSaleOutlined}
      historyItem={cloneHistoryLogAsGeneric(historyItem)}
      info={
        <Typography>
          Einzahlung | Datum: {moment(historyItem.logData.invoiceDate).format("DD.MM.YYYY")} | Belegnummer:{" "}
          {historyItem.logData.invoiceNumber !== "unknown" ? historyItem.logData.invoiceNumber : " unbekannt "} | Notiz:{" "}
          {historyItem.logData.note || " - "} | Angelegt von:{" "}
          {getCreatedBy(historyItem.logData.createdBy, backofficeUsers)} | Soll: - | Haben: {historyItem.logData.amount}{" "}
          € | Account: {historyItem.logData.abbreviation ? historyItem.logData.abbreviation : " unbekannt "} | Fall{" "}
          <LinkMui
            component={Link}
            to={getCaseLink({
              productClassName: historyItem.logData.previous.productType,
              id: historyItem.logData.previous.productId,
            })}
            target={"_blank"}
          >
            {historyItem.logData.previous.productId}
          </LinkMui>
          {" => "}
          <LinkMui
            component={Link}
            to={getCaseLink({
              productClassName: historyItem.logData.current.productType,
              id: historyItem.logData.current.productId,
            })}
            target={"_blank"}
          >
            {historyItem.logData.current.productId}
          </LinkMui>
        </Typography>
      }
    />
  );
}
