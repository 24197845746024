const faxTranslations = {
  targetNumber: {
    label: "Faxnummer",
  },
  status: {
    label: "Status",
    values: {
      INIT: "abgeschickt",
      FAILED: "fehlgeschlagen",
      SENT: "zugestellt",
    },
  },
};

export default faxTranslations;
