import { Moment } from "moment";
import moment from "moment/moment";
import { AbstractCase, productClassNames } from "../AbstractCase";

export function isMoment(value: any): value is Moment {
  return value instanceof moment;
}

export function isValidProductClassName(value?: string): value is AbstractCase["productClassName"] {
  return (productClassNames as readonly string[]).includes(value || "");
}
