import React, { useState } from "react";
import { Box, CircularProgress, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { Check, DoDisturbAlt, NotificationImportant, WarningAmberOutlined } from "@mui/icons-material";
import beaMessageStyles from "./beaMessageStyles";
import BeaAcknowledgementModal from "./BeaAcknowledgementModal";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import ApiClient from "../../services/ApiClient";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { BeaMessage } from "../../types/Bea/BeaMessage";
import { AbstractCase } from "../../types/AbstractCase";
import { Roles } from "../../types/BackofficeUser";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";

type BeaMessageActionsProps = {
  beaMessage: BeaMessage;
  refreshBeaMessages: Function;
  product: AbstractCase;
};
export default function BeaMessageActions({ beaMessage, refreshBeaMessages, product }: BeaMessageActionsProps) {
  const [beaAcknowledgementModalOpen, setBeaAcknowledgementModalOpen] = useState(false);
  const [eebFailedModalOpen, setEebFailedModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();

  if (isLoading) {
    return (
      <Box sx={beaMessageStyles.iconBox}>
        <CircularProgress />
      </Box>
    );
  }

  switch (beaMessage.transmissionDirection) {
    case "sent":
      const generateTransmissionProtocoll = async () => {
        setIsLoading(true);
        await ApiClient.post("bea/" + beaMessage.id + "/generateTransmissionReport", {
          body: JSON.stringify({
            productClassName: product.productClassName,
            productId: product.id,
          }),
        });
        refreshBeaMessages();
        setIsLoading(false);
      };

      return (
        <Box sx={beaMessageStyles.iconBox}>
          <PopUpMenu>
            <MenuItem onClick={generateTransmissionProtocoll}>Sendebericht generieren</MenuItem>
          </PopUpMenu>
        </Box>
      );
    default:
      return (
        <>
          {beaMessage.eEBStatus === "requested" && !beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime && (
            <>
              <Box sx={beaMessageStyles.iconBox}>
                <IconButton
                  onClick={() => setBeaAcknowledgementModalOpen(true)}
                  disabled={!userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.admin]) || !beaMessage.sender}
                >
                  <Tooltip title={"Empfangsbekenntnis abgeben"}>
                    <NotificationImportant sx={beaMessageStyles.icon} />
                  </Tooltip>
                </IconButton>
              </Box>
              <BeaAcknowledgementModal
                open={beaAcknowledgementModalOpen}
                handleClose={() => setBeaAcknowledgementModalOpen(false)}
                beaMessage={beaMessage}
                refreshBeaMessages={refreshBeaMessages}
              />
            </>
          )}
          {beaMessage.eEBStatus === "requested" && beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime && (
            <>
              <Box sx={beaMessageStyles.iconBox}>
                <IconButton onClick={() => setEebFailedModalOpen(true)}>
                  <Tooltip title={"Empfangsbekenntnis konnte nicht abgegeben werden"}>
                    <WarningAmberOutlined sx={beaMessageStyles.icon} />
                  </Tooltip>
                </IconButton>
              </Box>
              <LegalbirdIoModal
                open={eebFailedModalOpen}
                title={"eEB-Status unbekannt"}
                handleClose={() => setEebFailedModalOpen(false)}
              >
                <Typography sx={{ textAlign: "center" }}>
                  Der Versand der eEB hat systemseitig nicht funktioniert und kann nicht erneut über das System versucht
                  werden. Im Zweifel muss die eEB auf anderem Wege abgegeben werden.
                </Typography>
              </LegalbirdIoModal>
            </>
          )}
          {beaMessage.eEBStatus === "submitted" && (
            <Tooltip title={"Empfangsbekenntnis abgegeben"}>
              <Check sx={beaMessageStyles.icon} />
            </Tooltip>
          )}
          {beaMessage.eEBStatus === "rejected" && (
            <Tooltip title={"Empfangsbekenntnis abgelehnt"}>
              <DoDisturbAlt sx={beaMessageStyles.icon} />
            </Tooltip>
          )}
        </>
      );
  }
}
