import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { formValue } from "../../services/formServiceFunctions";
import ValidatorTextField from "../Validator/ValidatorTextField";
import {
  biggerOrSameDateThanDefault,
  dateFieldDefault,
  futureDateDefault,
  minStringLength,
  requiredFieldDefault,
  smallerOrSameDateThanDefault,
} from "../../services/validationRules";
import ValidatorDateField from "../Validator/ValidatorDatePicker";
import { translate } from "../../services/Translations/translatorService";
import ValidatorNumberField from "../Validator/ValidatorNumberField";
import { UseForm } from "../../types/UseForm";
import { fetchBudget } from "../../services/paidTaskService";

type PaidTaskFormFieldsProps = {
  handleChange: UseForm["handleChange"];
  registerValidators: UseForm["registerValidators"];
  errors: UseForm["errors"];
  handleBlur: UseForm["handleBlur"];
  handleDateChange: Function;
  values: UseForm["values"];
  isDisabled: boolean;
};

const PaidTaskFormFields = ({
  handleChange,
  registerValidators,
  errors,
  handleBlur,
  handleDateChange,
  values,
  isDisabled,
}: PaidTaskFormFieldsProps) => {
  const [remainingBudget, setRemainingBudget] = useState<number | null>(null);

  useEffect(() => {
    fetchBudget().then((result) => setRemainingBudget(result.remainingBudget));
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <ValidatorTextField
          label={translate("paidTask.subject")}
          name={"subject"}
          value={formValue(values, "subject")}
          onChange={handleChange}
          registerValidators={registerValidators}
          validators={requiredFieldDefault}
          inputProps={{ maxLength: 255 }}
          error={!!errors["subject"]}
          helperText={errors["subject"]}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField
          label={translate("paidTask.description")}
          name={"description"}
          value={formValue(values, "description")}
          onChange={handleChange}
          registerValidators={registerValidators}
          validators={requiredFieldDefault}
          error={!!errors["description"]}
          helperText={errors["description"]}
          multiline
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField
          label={translate("paidTask.definitionOfDone")}
          name={"definitionOfDone"}
          value={formValue(values, "definitionOfDone")}
          onChange={handleChange}
          registerValidators={registerValidators}
          validators={[
            ...requiredFieldDefault,
            {
              validator: minStringLength,
              params: [10],
              message: "Der Text ist zu kurz",
            },
          ]}
          error={!!errors["definitionOfDone"]}
          helperText={errors["definitionOfDone"]}
          multiline
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ValidatorNumberField
          label={translate("paidTask.fee")}
          disabled={isDisabled}
          name={"fee"}
          onChange={handleChange}
          onBlur={handleBlur}
          fieldType={"money"}
          value={formValue(values, "fee")}
          registerValidators={registerValidators}
          error={!!errors["fee"]}
          helperText={errors["fee"] || "Budget: " + remainingBudget + " €"}
        />
      </Grid>
      {/*removed onblur from both deadlines because dependentValidationFields doesn't work with dynamic values onblur. for more info see comment in LB-3522*/}
      <Grid item xs={12} md={4}>
        <ValidatorDateField
          label={translate("paidTask.assignmentDeadline")}
          name={"assignmentDeadline"}
          value={formValue(values, "assignmentDeadline", undefined)}
          format="DD.MM.YYYY"
          onChange={(date) => handleDateChange(date, "assignmentDeadline")}
          disabled={isDisabled}
          helperText={errors["assignmentDeadline"]}
          registerValidators={registerValidators}
          dependentValidationFields={["doneDeadline"]}
          validators={[
            ...requiredFieldDefault,
            ...dateFieldDefault,
            ...futureDateDefault,
            ...smallerOrSameDateThanDefault(values.doneDeadline, translate("paidTask.doneDeadline")),
          ]}
          handleBlur={function (): void {}}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ValidatorDateField
          label={translate("paidTask.doneDeadline")}
          name={"doneDeadline"}
          value={formValue(values, "doneDeadline", undefined)}
          format="DD.MM.YYYY"
          onChange={(date) => handleDateChange(date, "doneDeadline")}
          disabled={isDisabled}
          helperText={errors["doneDeadline"]}
          registerValidators={registerValidators}
          dependentValidationFields={["assignmentDeadline"]}
          validators={[
            ...requiredFieldDefault,
            ...dateFieldDefault,
            ...futureDateDefault,
            ...biggerOrSameDateThanDefault(values.assignmentDeadline, translate("paidTask.assignmentDeadline")),
          ]}
          handleBlur={function (): void {}}
        />
      </Grid>
    </>
  );
};

export default PaidTaskFormFields;
