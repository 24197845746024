import React, { useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import moment from "moment";

import { useSnackbar } from "notistack";
import { BeaMessage } from "../../types/Bea/BeaMessage";

type BeaAcknowledgementModalProps = {
  beaMessage: BeaMessage;
  open: boolean;
  handleClose: Function;
  refreshBeaMessages: Function;
};

export default function BeaAcknowledgementModal({
  beaMessage,
  open,
  handleClose,
  refreshBeaMessages,
}: BeaAcknowledgementModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [explanation, setExplanation] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setIsLoading(true);

    let acknowledgementOfReceiptPayload = {};

    switch (submit) {
      case true:
        acknowledgementOfReceiptPayload = {
          submitOrRejectDateTime: moment().format("c"),
        };
        break;
      case false:
        acknowledgementOfReceiptPayload = {
          rejectionReason: rejectionReason,
          explanation: explanation,
          submitOrRejectDateTime: moment().format("c"),
        };
        break;
    }

    try {
      await ApiClient.put(beaMessage.acknowledgementOfReceipt!["@id"], {
        body: JSON.stringify(acknowledgementOfReceiptPayload),
      });

      enqueueSnackbar(
        "Die elektronische Empfangsbekenntnis wurde abgegeben, sollte dies nicht direkt funktioniert haben wird es durch den Retry stündlich wieder versucht. Nach Erfolg verschwindet die eEB-Abgabefunktion dann",
        {
          variant: "custom",
          isNonInteractive: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    } catch (e) {}

    refreshBeaMessages();
    setIsLoading(false);
    handleClose();
  };

  return (
    <LegalbirdIoModal
      open={open}
      handleClose={handleClose}
      title={"beA-Empfangsbekenntnis"}
      submitButton={
        <ButtonLoading
          isLoading={isLoading}
          onClick={handleSubmit}
          variant={"contained"}
          disabled={!submit && (explanation === "" || rejectionReason === "")}
        >
          Auswahl bestätigen
        </ButtonLoading>
      }
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
        <Typography>eEB abgeben</Typography>
        <Switch value={submit} onClick={() => setSubmit(!submit)} />
        <Typography>eEB ablehnen</Typography>
      </Stack>
      {!submit && (
        <>
          <FormControl fullWidth>
            <InputLabel id="rejection-reason">Ablehnungsgrund</InputLabel>
            <Select
              label="Ablehnungsgrund"
              labelId="rejection-reason"
              name={"rejectionReason"}
              value={rejectionReason}
              onChange={(event) => setRejectionReason(event.target.value)}
            >
              <MenuItem value={"wrongRecipient"}>Zustellungsempfänger nicht am Verfahren beteiligt</MenuItem>
              <MenuItem value={"contentUnclear"}>Inhalt der Sendung unklar oder unvollständig</MenuItem>
              <MenuItem value={"certificateCheckFailed"}>Zertifikatspruefung fehlgeschlagen</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={"Erläuterung"}
            value={explanation}
            onChange={(event) => setExplanation(event.target.value)}
          />
        </>
      )}
    </LegalbirdIoModal>
  );
}
