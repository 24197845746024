import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { ProductSpecificFormDefinition } from "../../../types/ProductService/ProductService";

const subscriptionProductSpecificDefinition = (product: AbstractCase): ReturnType<ProductSpecificFormDefinition> => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "subscriptionTrap" },
      { value: "serviceUnavailable" },
      { value: "cancellationRefused" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.wasDeceived`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => trueFalseDefaultOptions(`${product.productClassName}.wasDeceived`),
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.salesChannel`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "phone" }, { value: "online" }, { value: "other" }],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.subscriptionName`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
];

export default subscriptionProductSpecificDefinition;
