import React, { useState } from "react";
import Button from "@mui/material/Button";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Grid, SelectChangeEvent } from "@mui/material";
import { convertToFloat, formValue } from "../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { dateFieldDefault, notNoneSelectValidator, requiredFieldDefault } from "../../services/validationRules";
import ValidatorDateField from "../Validator/ValidatorDatePicker";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import moment from "moment";
import SubmitButton from "../Button/SubmitButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createResource, fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { BookingEntry } from "../../types/BookingEntry";
import { AbstractCase } from "../../types/AbstractCase";
import { UseForm } from "../../types/UseForm";
import ValidatorNumberField from "../Validator/ValidatorNumberField";

type NewBookingEntryProps = {
  bookingEntries: BookingEntry[];
  product: AbstractCase;
  updateBookingEntries: () => void;
};

const NewBookingEntry = ({ bookingEntries, product, updateBookingEntries }: NewBookingEntryProps) => {
  const [newBookingEntryOpen, setNewBookingEntryOpen] = useState(false);
  const [receiptId, setReceiptId] = useState(0);
  const createMutation = useMutation(createResource);

  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };

  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const initialValues = {
    debitOrCredit: "credit",
    bookingDate: moment(),
    amount: "",
    invoiceNumber: "unknown",
    bookingText: "",
    type: "payment",
    vat: 19.0,
    organization: "/organizations/1",
    invoiceTypeIdentifier: null,
    isThirdPartyFunds: "",
  };

  const onSubmit = async ({ values }: { [key: string]: any }) => {
    if (!product?.customer?.id) {
      return;
    }
    let bookingEntryData = { ...values };
    bookingEntryData.amount = convertToFloat(bookingEntryData.amount);
    bookingEntryData.customer = product.customer.id;
    bookingEntryData.productType = product.productClassName;
    bookingEntryData.productId = product.id;
    //bookingEntryData.invoiceTypeIdentifier = invoiceTypeIdentifier;

    if (receiptId !== 0) {
      bookingEntryData.receipt = "/media_objects/" + receiptId;
    }
    if (bookingEntryData.type === "invoice") {
      bookingEntryData.invoiceNumber =
        product.reference +
        "-" +
        (bookingEntryData.debitOrCredit === "credit" ? "G" : "R") +
        bookingEntryData.invoiceNumber;
    }
    bookingEntryData.isThirdPartyFunds = showIsThirdPartyFunds ? bookingEntryData.isThirdPartyFunds === "true" : null;
    await createMutation.mutateAsync({ uri: "booking_entries", data: bookingEntryData });
    await updateBookingEntries();
    setNewBookingEntryOpen(false);
    setReceiptId(0);
    clearForm();
  };

  const handleCloseModal = () => {
    setReceiptId(0);
    clearForm();
    setNewBookingEntryOpen(false);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    registerValidators,
    handleBlur,
    touchedValues,
    handleDateChange,
    clearForm,
  }: UseForm = useForm({
    initialValues,
    onSubmit,
    identifier: product.id.toString(),
  });

  const showIsThirdPartyFunds = values.type === "payment" && values.debitOrCredit === "credit";

  const handleTypeChange = (event: SelectChangeEvent) => {
    handleChange({
      target: {
        name: "invoiceNumber",
        value: event.target.value === "invoice" ? "" : "unknown",
        type: "text",
      },
    });

    if (event.target.value !== "invoice") {
      setReceiptId(0);
    }

    handleChange(event);
  };

  if (!mediaObjects) {
    return null;
  }

  let availableInvoiceNumbers: string[] = [];

  bookingEntries.forEach((bookingEntry) => {
    if (
      bookingEntry.invoiceNumber &&
      bookingEntry.invoiceNumber !== "unknown" &&
      !availableInvoiceNumbers.includes(bookingEntry.invoiceNumber)
    ) {
      availableInvoiceNumbers.push(bookingEntry.invoiceNumber);
    }
  });

  const connectedReceiptIds = _.map(bookingEntries, (bookingEntry) =>
    bookingEntry.receipt ? bookingEntry.receipt.id : null
  );

  const availableMediaObjects = _.filter(mediaObjects["hydra:member"], (mediaObject) => {
    return mediaObject.mimeType === "application/pdf" && !connectedReceiptIds.includes(mediaObject.id);
  });

  return (
    <>
      <Button variant={"contained"} onClick={() => setNewBookingEntryOpen(true)} fullWidth={false}>
        Zahlung/Beleg hinzufügen
      </Button>
      <LegalbirdIoModal
        handleClose={handleCloseModal}
        open={newBookingEntryOpen}
        title={"Zahlung/Beleg hinzufügen"}
        disableBackdropClick
        submitButton={
          <SubmitButton
            onClick={handleSubmit}
            variant={"contained"}
            error={_.keys(errors).length > 0}
            disabled={_.keys(touchedValues).length === 0 || (values.type === "invoice" && receiptId === 0)}
          >
            Speichern
          </SubmitButton>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ValidatorSelect
              label={"Beleg oder Zahlung"}
              name={"type"}
              value={formValue(values, "type")}
              onChange={handleTypeChange}
            >
              <MenuItem value={"payment"}>Zahlung</MenuItem>
              <MenuItem value={"invoice"}>Beleg</MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={12}>
            <ValidatorSelect
              label={formValue(values, "type") === "payment" ? "Zahlungseingang/-ausgang" : "Rechnung oder Gutschrift?"}
              name={"debitOrCredit"}
              value={formValue(values, "debitOrCredit")}
              onChange={handleChange}
            >
              <MenuItem value={"credit"}>
                {formValue(values, "type") === "payment" ? "Zahlungseingang" : "Gutschrift"}
              </MenuItem>
              <MenuItem value={"debit"}>
                {formValue(values, "type") === "payment" ? "Zahlungsausgang" : "Rechnung"}
              </MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              format="DD.MM.YYYY"
              label={formValue(values, "type") === "payment" ? "Zahlungsdatum" : "Belegdatum"}
              name={"bookingDate"}
              onChange={(date) => handleDateChange(date, "bookingDate")}
              handleBlur={handleBlur}
              value={formValue(values, "bookingDate", "")}
              helperText={errors["bookingDate"]}
              registerValidators={registerValidators}
              validators={[...requiredFieldDefault, ...dateFieldDefault]}
              dependentValidationFields={[]}
              isHighlighted={false}
            />
          </Grid>
          {values.type === "invoice" && (
            <Grid item xs={12}>
              <ValidatorDateField
                format="DD.MM.YYYY"
                label={"Fällig am"}
                name={"dueDate"}
                onChange={(date) => handleDateChange(date, "dueDate")}
                handleBlur={handleBlur}
                value={formValue(values, "dueDate", "")}
                helperText={errors["dueDate"]}
                registerValidators={registerValidators}
                validators={dateFieldDefault}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ValidatorNumberField
              name={"amount"}
              value={formValue(values, "amount")}
              label={"Betrag"}
              fieldType={"money"}
              numberType={"float"}
              validators={requiredFieldDefault}
              isHighlighted={true}
              error={!!errors["amount"]}
              registerValidators={registerValidators}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorSelect label={"MwSt."} name={"vat"} value={formValue(values, "vat")} onChange={handleChange}>
              <MenuItem value={16.0}>16%</MenuItem>
              <MenuItem value={19.0}>19%</MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={12}>
            <ValidatorSelect
              label={"Gesellschaft"}
              name={"organization"}
              value={formValue(values, "organization")}
              onChange={handleChange}
            >
              <MenuItem value={"/organizations/1"}>LBRA</MenuItem>
              <MenuItem value={"/organizations/3"}>R&S</MenuItem>
            </ValidatorSelect>
          </Grid>
          <Grid item xs={12}>
            {formValue(values, "type") === "payment" ? (
              <ValidatorSelect
                label={"Belegnummer"}
                name={"invoiceNumber"}
                value={formValue(values, "invoiceNumber")}
                onChange={handleChange}
              >
                <MenuItem value={"unknown"}>Noch unbekannt</MenuItem>
                {availableInvoiceNumbers.map((invoiceNumber) => (
                  <MenuItem key={invoiceNumber} value={invoiceNumber}>
                    {invoiceNumber}
                  </MenuItem>
                ))}
              </ValidatorSelect>
            ) : (
              <ValidatorTextField
                label={"Belegnummer"}
                name={"invoiceNumber"}
                validators={requiredFieldDefault}
                registerValidators={registerValidators}
                value={formValue(values, "invoiceNumber")}
                error={!!errors["invoiceNumber"]}
                helperText={errors["invoiceNumber"]}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {product.reference + "-" + (formValue(values, "debitOrCredit") === "credit" ? "G" : "R")}
                    </InputAdornment>
                  ),
                }}
                isHighlighted={false}
                initialDependentValidationFields={[]}
              />
            )}
          </Grid>
          {showIsThirdPartyFunds && (
            <ValidatorSelect
              label={"Fremdgeld?"}
              name={"isThirdPartyFunds"}
              value={formValue(values, "isThirdPartyFunds")}
              onChange={handleChange}
              validators={notNoneSelectValidator}
              registerValidators={registerValidators}
              helperText={errors["isThirdPartyFunds"]}
              error={!!errors["isThirdPartyFunds"]}
              onBlur={handleBlur}
            >
              <MenuItem value={"true"}>Ja</MenuItem>
              <MenuItem value={"false"}>Nein</MenuItem>
            </ValidatorSelect>
          )}
          {values.type === "invoice" && (
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Welcher Beleg soll verknüpft werden?"}
                name={"receipt"}
                value={receiptId}
                onChange={(event: SelectChangeEvent) => setReceiptId(parseInt(event.target.value))}
              >
                <MenuItem value={0} disabled>
                  Bitte auswählen
                </MenuItem>
                {_.map(availableMediaObjects, (mediaObject) => (
                  <MenuItem key={mediaObject.id} value={mediaObject.id}>
                    {mediaObject.description}
                  </MenuItem>
                ))}
              </ValidatorSelect>
            </Grid>
          )}
          {values.type === "payment" && values.debitOrCredit === "debit" && (
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Art der Auszahlung (Identifier)?"}
                name={"invoiceTypeIdentifier"}
                value={formValue(values, "invoiceTypeIdentifier")}
                onChange={handleChange}
                helperText={errors["invoiceTypeIdentifier"]}
                error={!!errors["invoiceTypeIdentifier"]}
                validators={notNoneSelectValidator}
                registerValidators={registerValidators}
                dependentValidationFields={["invoiceTypeIdentifier"]}
                onBlur={handleBlur}
              >
                <MenuItem value={"none"} disabled>
                  Bitte auswählen
                </MenuItem>
                <MenuItem value={"backofficeCustom"}>Allgemeine Auszahlung</MenuItem>
                <MenuItem value={"refund"}>Storno Rückerstattung</MenuItem>
              </ValidatorSelect>
            </Grid>
          )}
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Buchungstext"}
              name={"bookingText"}
              value={formValue(values, "bookingText")}
              onChange={handleChange}
              registerValidators={() => {}}
              validators={[]}
              isHighlighted={false}
              initialDependentValidationFields={[]}
            />
          </Grid>
        </Grid>
      </LegalbirdIoModal>
    </>
  );
};

export default NewBookingEntry;
