/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { dateFieldDefault, postalCodeDefault, textFieldDefault } from "../../../validationRules";
import { formValue } from "../../../formServiceFunctions";
import basicInsuranceFields from "../modules/basicInsuranceFields";
import * as stagesTraffic from "../../../Stages/StagesTraffic";
import { trueFalseDefaultOptions, yesNoDefaultOptions, yesNoUnknownDefaultOptions } from "../formDefinitionFunctions";
import _ from "lodash";
import { STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID } from "../../../Stages/StagesTraffic";
import { getCourtAppointmentFields } from "../contract/fullFormDefinition";
import { hasActiveCourtAppointment } from "../../ProductService";
import { allDataPageCourtFields } from "../allDataPageCourtFields";
import { AbstractCase } from "../../../../types/AbstractCase";
import { UseForm } from "../../../../types/UseForm";

const prosecution = {
  label: "Staatsanwaltschaft",
  elements: [
    {
      type: "ProsecutionPicker",
      path: "traffic.responsibleProsecution",
      additionalProps: {
        fullWidth: true,
      },
    },
    {
      type: "ValidatorTextField",
      path: "traffic.prosecutionReference",
      validators: textFieldDefault,
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
};
const insurance = (product: AbstractCase) => ({
  label: "Versicherung",
  isHidden: ({ values }: { values: UseForm["values"] }) => formValue(values, "traffic.paymentType") !== "insurance",
  elements: [
    ...basicInsuranceFields({ path: "traffic.insurance", product: product }),
    {
      type: "ValidatorNumberField",
      path: "traffic.insurance.deductible",
      fieldType: "money",
      numberType: "float",
    },
    {
      type: "ValidatorDateField",
      path: "traffic.insuranceCoverageDate",
      isHidden: ({ values }: { values: UseForm["values"] }) => formValue(values, "traffic.paymentType") !== "insurance",
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage !== stagesTraffic.STAGE_ACCESS_TO_RECORDS,
      validators: dateFieldDefault,
    },
  ],
});
const authority = {
  label: "Behörde",
  elements: [
    {
      type: "AuthorityPicker",
      path: "traffic.responsibleAuthority",
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
};

export const fullFormDefinition = (product: AbstractCase) => ({
  product: {
    sections: [
      {
        label: "Zum Verstoß",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.accusation",
            isDisabled: () => true,
            options: () => [
              { value: "__null__" },
              { value: "speeding" },
              { value: "red_light" },
              { value: "distance" },
              { value: "other" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.accusationOther",
            isDisabled: () => true,
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "other",
            options: () => [
              { value: "__null__" },
              { value: "mobile_phone" },
              { value: "drugs" },
              { value: "parking" },
              { value: "other" },
            ],
          },
          /* ============== RED LIGHT ==================== */
          {
            type: "ValidatorSelect",
            path: "traffic.roadUsersEndangered",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoDefaultOptions("traffic.roadUsersEndangered"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.damage",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoUnknownDefaultOptions("traffic.damage"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.redLight",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "red_light",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "under_1_sec" },
              { value: "1_sec_or_more" },
              { value: "unknown" },
            ],
          },
          /* ============== DISTANCE =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.speed",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "distance",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "<81" },
              { value: "81-100" },
              { value: "101-130" },
              { value: ">130" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.distance",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "distance",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "5/10" },
              { value: "4/10" },
              { value: "3/10" },
              { value: "2/10" },
              { value: "1/10" },
              { value: "unknown" },
            ],
          },
          /* ============== SPEEDING =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.locality",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "speeding",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "in_town" },
              { value: "out_of_town" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.speeding",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusation") !== "speeding",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "0-20" },
              { value: "21-30" },
              { value: "31-40" },
              { value: ">40" },
              { value: "41-50" },
              { value: "51-60" },
              { value: "61-70" },
              { value: ">70" },
              { value: "unknown" },
            ],
          },
          /* ============== MOBILE PHONE =================== */
          {
            type: "ValidatorSelect",
            path: "traffic.vehicle",
            isHidden: ({ values }: { values: UseForm["values"] }) =>
              formValue(values, "traffic.accusationOther") !== "mobile_phone",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "car" },
              { value: "bike" },
              { value: "motorcycle" },
              { value: "truck" },
              { value: "bus" },
            ],
          },
          /* ============== OPTIONALS END ===================== */
          {
            type: "ValidatorSelect",
            path: "traffic.driversLicensePeriod",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [{ value: "__null__" }, { value: "<2" }, { value: "2-10" }, { value: ">10" }],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.flensburgPoints",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "none" },
              { value: "1-3" },
              { value: "4-8" },
              { value: "unknown" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.flashed",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => yesNoUnknownDefaultOptions("traffic.flashed"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.driversLicenseIndispensable",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [
              { value: "__null__" },
              { value: "yes_professional" },
              { value: "yes_other" },
              { value: "no" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "traffic.legalExpenseInsurance",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => trueFalseDefaultOptions("traffic.legalExpenseInsurance"),
          },
        ],
      },
      {
        label: "Zur Person",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }, { value: "diverse" }],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.petitioner.birthDate",
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.streetAddress",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.postalCode",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.petitioner.residenceAddress.addressLocality",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_COMPLETED,
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Ergänzende Angaben",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.authorityNotification",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) > stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            options: () => [
              { value: "__null__" },
              { value: "hearing_report" },
              { value: "witness_report" },
              { value: "fine_notice" },
              { value: "no_notice" },
            ],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineNoticeReceivedDate",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineNoticeDate",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.fineDeadline",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.fineNoticeReference",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorNumberField",
            path: "traffic.finalFineAmount",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            fieldType: "money",
            numberType: "float",
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "traffic.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.paymentType",
            isDisabled: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_CHECKED,
            options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "private" }],
          },
          {
            type: "ValidatorDateField",
            path: "traffic.customerAppealRevocationApproved",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_ACCESS_TO_RECORDS ||
              (product.stage || 0) > stagesTraffic.STAGE_DECISION_MADE,
            validators: dateFieldDefault,
            additionalProps: {
              helperText:
                'Dieses Feld stößt automatisch den Prozess zur Rücknahme des Einspruchs an (inkl. automatisches Schieben der Stage) und befüllt auch das Feld "Einspruch zurückgenommen am".',
            },
            alert: {
              onInitial:
                "Das Speichern führt dazu, dass hintergründig ein Fax mit der Einspruchsrücknahme an die zuständige Stelle verschickt wird. Möchten Sie, dass Feld speichern und somit das Fax mit der Rücknahme versenden?",
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.appealRevoked",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_ACCESS_TO_RECORDS ||
              (product.stage || 0) > stagesTraffic.STAGE_DECISION_MADE,
            validators: dateFieldDefault,
            additionalProps: {
              helperText:
                "Achtung: Befüllt sich automatisch. Bitte nur befüllen, wenn automatischer Versand nicht funktioniert hat!",
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.processStoppedDate",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST ||
              (product.stage || 0) > stagesTraffic.STAGE_EXTRAJUDICIAL_PAID,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: "Außergerichtliches Verfahren",
        elements: [
          {
            type: "ValidatorDateField",
            path: "traffic.accessToRecordRequested",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              ![stagesTraffic.STAGE_ACCESS_TO_RECORDS, stagesTraffic.STAGE_CHECKED].includes(product.stage || 0),
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.opinionRequired",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              !_.includes([STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID], product.stage),
            options: ({ product }: { product: AbstractCase }) =>
              trueFalseDefaultOptions(`${product.productClassName}.opinionRequired`),
          },
          {
            type: "ValidatorDateField",
            path: "traffic.opinionSubmitted",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              product.stage !== stagesTraffic.STAGE_EXTRAJUDICIAL_PAID &&
              product.stage !== stagesTraffic.STAGE_ACCESS_TO_RECORDS,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "traffic.authorityAnswered",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              product.stage !== stagesTraffic.STAGE_OPINION_SUBMITTED,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "traffic.authorityDecision",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              product.stage !== stagesTraffic.STAGE_OPINION_SUBMITTED,
            options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "changed" }, { value: "court" }],
          },
        ],
      },
      {
        label: "Gerichtliches Verfahren",
        isHidden: ({ product }: { product: AbstractCase }) => product.authorityDecision === "revoked",
        elements: [
          {
            type: "ValidatorSelect",
            path: "traffic.trialWanted",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            options: () => yesNoDefaultOptions("traffic.trialWanted"),
          },
          {
            type: "ValidatorSelect",
            path: "traffic.courtNotice",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            options: () => [{ value: "__null__" }, { value: "revoked" }, { value: "no_trial" }, { value: "trial" }],
          },
          {
            type: "ValidatorTextField",
            path: "traffic.courtReference",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "traffic.responsibleCourtAddress.postalCode",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              product.stage !== stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            isHidden: ({ product }: { product: AbstractCase }) => (product.stage || 0) >= stagesTraffic.STAGE_TRIAL,
            validators: textFieldDefault,
            alert: {
              onChange:
                "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
            },
          },
          {
            type: "ValidatorDateField",
            path: "traffic.courtDecisionDate",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_AUTHORITY_ANSWERED,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }: { product: AbstractCase }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  processParticipants: {
    sections: [insurance(product), authority, prosecution],
  },
  additionalProcessParticipants: {
    sections: [
      {
        label: "Personen",
        elements: [
          {
            type: "ProcessParticipantsPeopleForm",
            path: `${product.productClassName}.relatedPeople`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Unternehmen",
        elements: [
          {
            type: "ProcessParticipantsOrganizationsForm",
            path: `${product.productClassName}.relatedOrganizations`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
    withoutSubmit: true,
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "traffic.responsibleCourt",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) < stagesTraffic.STAGE_TRIAL || hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht", "Mahngericht"],
            },
          },
          ...allDataPageCourtFields("traffic", "responsibleCourt"),
        ],
      },
    ],
  },
});
