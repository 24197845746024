import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { trackPage } from "../services/GoogleAnalytics/googleAnalytics";
import LoginPage from "../components/_Pages/LoginPage/LoginPage";
import NewEmailPage from "../components/_Pages/NewEmailPage/NewEmailPage";
import NewPasswordPage from "../components/_Pages/NewPasswordPage/NewPasswordPage";
import ConfirmAccountPage from "../components/_Pages/ConfirmAccountPage/ConfirmAccountPage";
import TermsOfServiceExternalLawyerPage from "../components/_Pages/TermsOfServiceExternalLawyerPage/TermsOfServiceExternalLawyerPage";
import PrivacyPage from "../components/_Pages/PrivacyPage/PrivacyPage";
import ImprintPage from "../components/_Pages/ImprintPage/ImprintPage";
import CookiePolicyPage from "../components/_Pages/CookiePolicyPage/CookiePolicyPage";
import MaintenancePage from "../components/_Pages/MaintenancePage/MaintenancePage";
import ExternalReferenceFilePage from "../components/_Pages/ExternalReferenceFilePage/ExternalReferenceFilePage";
import LayoutPublicPage from "../components/LayoutPublicPage/LayoutPublicPage";
import ThemeOverviewPage from "../components/_Pages/ThemeOverviewPage/ThemeOverviewPage";

export default function PublicRoutes() {
  const { pathname } = useLocation();
  useEffect(() => trackPage(pathname), [pathname]);
  return (
    <LayoutPublicPage>
      <Routes>
        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/neue-email/setzen"} element={<NewEmailPage />} />
        <Route path={"/passwort/setzen"} element={<NewPasswordPage />} />
        <Route path={"/konto-bestaetigen"} element={<ConfirmAccountPage />} />
        <Route path={"/nutzungsbedingungen"} element={<TermsOfServiceExternalLawyerPage />} />
        <Route path={"/datenschutz"} element={<PrivacyPage />} />
        <Route path={"/impressum"} element={<ImprintPage />} />
        <Route path={"/cookierichtlinie"} element={<CookiePolicyPage />} />
        <Route path={"/maintenance"} element={<MaintenancePage />} />
        <Route path={"/handakte/:accessToken"} element={<ExternalReferenceFilePage />} />
        <Route path={"/theme"} element={<ThemeOverviewPage />} />
      </Routes>
    </LayoutPublicPage>
  );
}
