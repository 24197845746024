/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Box, Typography } from "@mui/material";

import { Navigate } from "react-router-dom";
import Colors from "../../ThemeOverview/Colors";
import TypographyVariants from "../../ThemeOverview/TypographyVariants";

const ThemeOverviewPage: React.FC = () => {
  if (process.env.REACT_APP_DEPLOYMENT === "production") {
    return <Navigate to={"/"} />;
  }

  return (
    <Box p={2}>
      <Typography variant="h1" gutterBottom>
        Theme Overview
      </Typography>

      <Colors />

      <TypographyVariants />
    </Box>
  );
};

export default ThemeOverviewPage;
