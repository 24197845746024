import React, { useState } from "react";
import {
  CreditCardOffOutlined,
  CreditCardOutlined,
  EventBusyOutlined,
  ListAltOutlined,
  MessageOutlined,
  NewReleasesOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Notification } from "../../../types/Notification";
import ApiClient from "../../../services/ApiClient";
import NavigationBarNotificationItemStyle from "./NavigationBarNotificationItemStyle";
import truncate from "truncate-html";
import moment from "moment";
import Grid from "@mui/material/Grid";

const getIconForType = (type: string) => {
  switch (type) {
    case "release-update":
      return <NewReleasesOutlined />;
    case "new-message":
      return <MessageOutlined />;
    case "check-transfer":
      return <CreditCardOutlined />;
    case "deleted-transfer":
      return <CreditCardOffOutlined />;
    case "paid-task-request":
      return <ListAltOutlined />;
    case "paid-task-overdue":
      return <EventBusyOutlined />;
    default:
      return <NewReleasesOutlined />;
  }
};

export default function NavigationBarNotificationItem({
  notification,
  handleItemClick,
}: {
  notification: Notification;
  handleItemClick?: () => void;
}) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const markAsRead = async (notification: Notification) => {
    if (!notification.isRead) {
      await ApiClient.put(`notifications/${notification.id}`, {
        body: JSON.stringify({ isRead: true }),
      });
    }
  };

  // the easiest way to strip away html tags in javascript without installing another 3rd party package
  const getPlainText = (html: string): string => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const handleClick = async () => {
    if (notification.link) {
      window.open(notification.link, "_blank");
    }

    await markAsRead(notification);

    if (handleItemClick) {
      handleItemClick();
    }
  };

  const handleInnerClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (notification.content !== null && notification.content.length > 125) {
      if (!isContentExpanded && !notification.isRead) {
        markAsRead(notification);
      }

      setIsContentExpanded(!isContentExpanded);
    } else {
      handleClick();
    }
  };

  let displayedContent = "";

  if (notification.content !== null) {
    if (getPlainText(notification.content).length > 125 && !isContentExpanded) {
      displayedContent = truncate(notification.content, 125);
    } else {
      displayedContent = notification.content;
    }
  }

  return (
    <Grid container alignItems="start" justifyContent="space-between" spacing={0.5} onClick={handleClick}>
      <Grid item sx={{ paddingRight: "0.5rem" }}>
        {getIconForType(notification.type)}
      </Grid>
      <Grid item flex={1}>
        <Typography variant="h6" sx={{ textWrap: "wrap", lineHeight: 1.2 }}>
          <strong>{notification.title}</strong>
        </Typography>
        <Typography variant={"subtitle2"} color="textSecondary" sx={{ mt: "0.25rem" }}>
          {moment(notification.created).format("DD.MM.YYYY HH:mm")} Uhr
        </Typography>
        {displayedContent !== "" && (
          <Box
            onClick={handleInnerClick}
            sx={NavigationBarNotificationItemStyle.contentBox}
            dangerouslySetInnerHTML={{ __html: displayedContent }}
          />
        )}
      </Grid>
      <Grid item>
        <Box
          sx={{
            ...NavigationBarNotificationItemStyle.notificationBubble,
            visibility: notification.isRead ? "hidden" : "visible",
          }}
        >
          &#9679;
        </Box>
      </Grid>
    </Grid>
  );
}
