import React, { useState } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import { Box } from "@mui/material";
import {
  FILE_NAME_LENGTH_LIMIT,
  FILE_SIZE_LIMIT_EXCEEDED,
  FILE_SIZE_LIMIT_WARNING,
  isValidUploadableFile
} from "../../../services/mediaObjectService";
import uploadFileStyles from "./uploadFileStyles";

type LegalbirdDropzoneProps = {
  files: File[];
  setFiles: Function;
  acceptedFiles?: Array<string>;
  key?: number;
};

export default function LegalbirdDropzone({ files, setFiles, acceptedFiles, key }: LegalbirdDropzoneProps) {
  const [error, setError] = useState<"fileNameFormat" | null>(null);

  const fileSize = files[0] ? files[0].size : 0;
  const fileContainerStyles =
    fileSize > FILE_SIZE_LIMIT_WARNING || error
      ? { ...uploadFileStyles.fileContainer, ...uploadFileStyles.borderWarning }
      : { ...uploadFileStyles.fileContainer, ...uploadFileStyles.border };

  const handleChange = (files: File[]) => {
    for (const file of files) {
      if (!isValidUploadableFile(file.name)) {
        setError("fileNameFormat");
        setFiles([]);
        return;
      }
    }
    setError(null);
    setFiles(files);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={fileContainerStyles}>
        <DropzoneArea
          key={key}
          onChange={handleChange}
          filesLimit={1}
          dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
          showFileNames
          showAlerts={false}
          maxFileSize={300000000}
          useChipsForPreview
          fileObjects={files}
          acceptedFiles={acceptedFiles}
          onDelete={() => {}}
          clearOnUnmount={true}
        />
      </Box>
      <br />
      {error === "fileNameFormat" && (
        <Box sx={uploadFileStyles.warning}>
          Dateiname hat ein falsches Format. Bitte prüfen, dass der Dateiname nicht länger als {FILE_NAME_LENGTH_LIMIT} Zeichen ist und
          keine Sonderzeichen, Leerzeichen oder Punkte vorhanden sind.
        </Box>
      )}
      {fileSize > FILE_SIZE_LIMIT_WARNING && fileSize < FILE_SIZE_LIMIT_EXCEEDED && (
        <Box sx={uploadFileStyles.warning}>
          Achtung: Die Datei ist über 10 MB groß. Wollen Sie diese trotzdem hochladen?
        </Box>
      )}
      {fileSize >= FILE_SIZE_LIMIT_EXCEEDED && (
        <Box sx={uploadFileStyles.warning}>
          Achtung: Die Datei ist über 20 MB groß und kann nicht hochgeladen werden.
        </Box>
      )}
    </Box>
  );
}
