import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { formValue } from "../../formServiceFunctions";
import { ProductSpecificFormDefinition } from "../../../types/ProductService/ProductService";

const membershipProductSpecificDefinition = (product: AbstractCase): ReturnType<ProductSpecificFormDefinition> => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }: { product: any }) => parseInt(product.stage) >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "cancellationRefused" },
      { value: "cancellationByContractPartner" },
      { value: "priceIncrease" },
      { value: "invoiceIncorrect" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.membershipType`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "gym" }, { value: "club" }, { value: "other" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.serviceUnavailableOffer`,
    isDisabled: () => true,
    options: () => [{ value: "coupon" }, { value: "freeMonths" }, { value: "couponFreeMonths" }, { value: "noOffer" }],
    isHidden: () =>
      !["serviceUnavailable", "serviceUnavailableCancellationCombined"].includes(product.reasonForDispute),
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.feePaymentFrequency`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "monthly" }, { value: "quarterly" }, { value: "yearly" }],
  },
  {
    type: "ValidatorNumberField",
    path: `${product.productClassName}.fee`,
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationDate`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: () =>
      !["cancellationContractExtension", "cancellationRefused", "serviceUnavailableCancellationCombined"].includes(
        product.reasonForDispute
      ),
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.opponentReactionToCancellation`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }: { values: any; product: any }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused",
    options: () => [{ value: "noReaction" }, { value: "cancellationRefused" }, { value: "differentDate" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDateOpponent`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }: { values: any; product: any }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "cancellationRefused" ||
      formValue(values, `${product.productClassName}.opponentReactionToCancellation`) !== "differentDate",
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: () =>
      !["cancellationContractExtension", "cancellationRefused", "serviceUnavailableCancellationCombined"].includes(
        product.reasonForDispute
      ),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.serviceUnavailableFromDate`,
    isDisabled: () => true,
    isHidden: () =>
      !["serviceUnavailable", "serviceUnavailableCancellationCombined"].includes(product.reasonForDispute),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.serviceUnavailableToDate`,
    isDisabled: () => true,
    isHidden: () =>
      !["serviceUnavailable", "serviceUnavailableCancellationCombined"].includes(product.reasonForDispute),
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.numberDaysAvailableInBetween`,
    isDisabled: () => true,
    isHidden: () =>
      !["serviceUnavailable", "serviceUnavailableCancellationCombined"].includes(product.reasonForDispute),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.priceIncreaseAnnouncementDate`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }: { values: any; product: any }) =>
      formValue(values, `${product.productClassName}.reasonForDispute`) !== "priceIncrease",
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.numberUnpaidPeriods`,
    isDisabled: ({ product }: { product: any }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
];

export default membershipProductSpecificDefinition;
