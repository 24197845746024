import { InvoiceDeleteHistoryLog } from "../../../../types/HistoryLog";
import GenericLogEntry from "../GenericLogEntry";
import { translate } from "../../../../services/Translations/translatorService";
import { PointOfSaleOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { getCreatedBy } from "../../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import moment from "moment";
import { cloneHistoryLogAsGeneric } from "../../../../services/historyService";

type CaseHistoryInvoiceDeleteProps = {
  historyItem: InvoiceDeleteHistoryLog;
};

export default function CaseHistoryInvoiceDelete({ historyItem }: CaseHistoryInvoiceDeleteProps) {
  const { backofficeUsers } = useBackofficeUser();

  return (
    <GenericLogEntry
      subject={translate("historyItem.invoiceDelete.label")}
      Icon={PointOfSaleOutlined}
      historyItem={cloneHistoryLogAsGeneric(historyItem)}
      info={
        <Typography>
          Einzahlung | Datum: {moment(historyItem.logData.invoiceDate).format("DD.MM.YYYY")} | Belegnummer:{" "}
          {historyItem.logData.invoiceNumber !== "unknown" ? historyItem.logData.invoiceNumber : " unbekannt "} | Notiz:{" "}
          {historyItem.logData.note ? historyItem.logData.note : " - "} | Angelegt von:{" "}
          {getCreatedBy(historyItem.logData.createdBy, backofficeUsers)} | Soll: - | Haben: {historyItem.logData.amount}{" "}
          € | Account: {historyItem.logData.abbreviation ? historyItem.logData.abbreviation : " unbekannt "}
        </Typography>
      }
      lineThrough={true}
    />
  );
}
